import type { FlagConfiguration } from '@atlassian/jira-flags';
import messages from './messages.tsx';

export const fetchError = (): FlagConfiguration => ({
	type: 'error',
	title: messages.genericTitle,
	description: messages.genericDescription,
	isAutoDismiss: false,
	messageId: 'software-board-settings-date-fields.common.ui.error',
	messageType: 'transactional',
});
