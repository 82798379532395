// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getCardColors = (state: State) => state.cardColors;

export const useCardColors = createHook(Store, {
	selector: getCardColors,
});

export const getCardColorStrategy = (state: State) => state.cardColorStrategy;

export const useCardColorStrategy = createHook(Store, {
	selector: getCardColorStrategy,
});

export const getNextCardColorStrategy = (state: State) => state.nextCardColorStrategy;

export const useNextCardColorStrategy = createHook(Store, {
	selector: getNextCardColorStrategy,
});

export const getIsOptimisticCardColors = (state: State): boolean => state.isOptimisticCardColors;

export const useIsOptimisticCardColors = createHook(Store, {
	selector: getIsOptimisticCardColors,
});

export const getItemToEdit = (state: State) => state.itemToEdit;

export const useItemToEdit = createHook(Store, { selector: getItemToEdit });
