import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import type { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import { token } from '@atlaskit/tokens';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import { TableColumnHandlerKey } from './constants.tsx';
import { DragHandle } from './drag-handle/index.tsx';
import { ReorderHeader } from './reorder-header/index.tsx';
import { useHandleRank } from './utils.tsx';

type RowTypeWithLabel = RowType & {
	label: string;
};

type Props = {
	head: HeadType;
	rows: RowTypeWithLabel[];
	onRank: (index: number, afterItemIndex: number | null) => void;
	isReadOnly?: boolean;
	emptyView?: React.ReactElement;
};

export const SortableTable = ({ head, rows, onRank, isReadOnly, emptyView }: Props) => {
	const { onDragHandleRank, onRankEnd } = useHandleRank(onRank);

	const headConfig = useMemo(
		() =>
			isReadOnly
				? head
				: {
						...head,
						cells: [
							{
								key: TableColumnHandlerKey,
								width: 1,
								content: <ReorderHeader />,
							},
							...head.cells,
						],
					},
		[head, isReadOnly],
	);

	const rowsConfig = useMemo(
		() =>
			isReadOnly
				? rows
				: rows.map((row, index) => ({
						...row,
						cells: [
							{
								key: TableColumnHandlerKey,
								content: (
									<DragHandle
										name={row.label}
										index={index}
										onRank={onDragHandleRank}
										isTop={index === 0}
										isBottom={index + 1 === rows.length}
									/>
								),
							},
							...row.cells,
						],
					})),
		[rows, onDragHandleRank, isReadOnly],
	);

	return (
		<Container data-testId={dataTestIds.table}>
			<DynamicTableStateless
				head={headConfig}
				rows={rowsConfig}
				isRankable={!isReadOnly}
				onRankEnd={onRankEnd}
				emptyView={emptyView}
				testId={dataTestIds.table}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginBottom: token('space.0'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > table': {
		borderBottom: 'none',
		width: '100%',
	},
});
