// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateOldDoneIssueCutoff =
	(oldDoneIssuesCutoff: string) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			oldDoneIssuesCutoff,
		});
	};

export type UpdateOldDoneIssueCutoff = typeof updateOldDoneIssueCutoff;
