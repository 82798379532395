import React from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { SharePermissionOrPrivate } from '../../../model/types.tsx';
import ShareeListRow from './sharee-list-row/index.tsx';

type Props = {
	duplicatedIndex: number | undefined;
	sharePermissionRows: SharePermissionOrPrivate[];
	onRemove: (arg1: number, arg2: UIAnalyticsEvent) => void;
	forceHideRemoveButton: boolean | undefined;
	isEditorList: boolean;
};

export default function ShareeList(props: Props) {
	const { sharePermissionRows, onRemove, duplicatedIndex, forceHideRemoveButton, isEditorList } =
		props;

	const content = (
		<>
			{sharePermissionRows.map((sharePermission, index) => (
				<ShareeListRow
					index={index}
					key={index}
					sharePermission={sharePermission}
					onRemove={onRemove}
					isDuplicated={duplicatedIndex === index}
					forceHideRemoveButton={forceHideRemoveButton}
					isEditorRow={isEditorList}
				/>
			))}
		</>
	);

	if (isVisualRefreshEnabled()) {
		return (
			<Box xcss={shareeListStyles} role="list">
				{content}
			</Box>
		);
	}

	return <ShareeListContainer role="list">{content}</ShareeListContainer>;
}

ShareeList.defaultProps = {
	duplicatedIndex: undefined,
	forceHideRemoveButton: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShareeListContainer = styled.div({
	borderTop: `2px solid ${token('color.border')}`,
	paddingTop: token('space.100'),
});

const shareeListStyles = xcss({
	paddingTop: 'space.100',
});
