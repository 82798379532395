import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unassignedDisplayValue: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.unassigned-display-value',
		defaultMessage: 'Unassigned',
		description: 'Display value for card colour with the value of unassigned',
	},
	staleColorWarningMessageForAssignee: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-assignee',
		defaultMessage: 'There are currently no issues that have this assignee.',
		description: 'Warning message for stale colour of assignee color strategy.',
	},
	staleColorWarningMessageForIssueType: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-issue-type',
		defaultMessage: 'There are currently no issues using this issue type.',
		description: 'Warning message for stale colour of issue type color strategy.',
	},
	staleColorWarningMessageForRequestType: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-request-type',
		defaultMessage: 'There are currently no issues using this request type.',
		description: 'Warning message for stale colour of request type color strategy.',
	},
	staleColorWarningMessageForPriority: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-priority',
		defaultMessage: 'There are currently no work items using this priority.',
		description: 'Warning message for stale colour of pririty color strategy.',
	},
	staleColorWarningMessageForAssigneeIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-assignee-issue-term-refresh',
		defaultMessage: 'There are currently no work items that have this assignee.',
		description: 'Warning message for stale colour of assignee color strategy.',
	},
	staleColorWarningMessageForIssueTypeIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-issue-type-issue-term-refresh',
		defaultMessage: 'There are currently no work items using this work type.',
		description: 'Warning message for stale colour of issue type color strategy.',
	},
	staleColorWarningMessageForRequestTypeIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-request-type-issue-term-refresh',
		defaultMessage: 'There are currently no work items using this request type.',
		description: 'Warning message for stale colour of request type color strategy.',
	},
	staleColorWarningMessageForPriorityIssueTermRefresh: {
		id: 'software-board-settings-card-colors.card-colors-settings-page.strategy-content.non-query-strategy.stale-color-warning-message-for-priority-issue-term-refresh',
		defaultMessage: 'There are currently no work items that have this priority.',
		description: 'Warning message for stale colour of priority color strategy.',
	},
});
