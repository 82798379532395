import React, { useCallback, useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import { Flex, Box, xcss, Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import { COLOR_CARD_SIZE } from '../../../common/constants/index.tsx';
import { useDefaultI18nColorPalette } from '../../../controllers/use-default-color-palette/index.tsx';
import { ColorCard } from './color-card/index.tsx';
import messages from './messages.tsx';
import { getSelectedValueInPalette, getWidth, useColorPaletteMenuLabel } from './utils.tsx';

export type Props = {
	label: string;
	selectedColor?: string;
	cols?: number;
	onChange: (value: string) => void;
	onResetToGlobal?: () => void;
	onClose?: () => void;
	isInlineUpdate?: boolean;
	isShowingCustomColor?: boolean;
	isResetToGlobalAllowed?: boolean;
	informationMessage?: string;
	resetButtonTooltipContent?: string;
};

export const ColorPaletteMenu = ({
	onChange,
	onClose,
	onResetToGlobal,
	selectedColor,
	informationMessage,
	label,
	cols = 10,
	isInlineUpdate = false,
	isShowingCustomColor = true,
	isResetToGlobalAllowed = false,
	resetButtonTooltipContent,
}: Props) => {
	const palette = useDefaultI18nColorPalette();
	const fullLabel = useColorPaletteMenuLabel(label, selectedColor);

	const [pendingSelectedColor, setPendingSelectedColor] = useState<string | null>(null);
	const selectedValueInPalette = getSelectedValueInPalette(
		palette,
		pendingSelectedColor ?? selectedColor,
	);
	const { formatMessage } = useIntl();

	const shouldUpdateCustomColorWithConfirmation = useMemo(
		() => isInlineUpdate && isShowingCustomColor,
		[isInlineUpdate, isShowingCustomColor],
	);

	const isCustomColor =
		((shouldUpdateCustomColorWithConfirmation && pendingSelectedColor) ||
			!selectedValueInPalette) &&
		selectedColor;

	const handleClick = useCallback(
		(value: string) => {
			if (!isCustomColor || !shouldUpdateCustomColorWithConfirmation) {
				onChange(value);
				onClose && onClose();
			} else {
				setPendingSelectedColor(value);
			}
		},
		[onChange, onClose, isCustomColor, shouldUpdateCustomColorWithConfirmation],
	);

	const handleKeyDown = useCallback(
		(value: string) => {
			if (!isCustomColor) {
				onChange(value);
			} else {
				setPendingSelectedColor(value);
			}
		},
		[isCustomColor, onChange],
	);

	const handleSubmitPendingColor = useCallback(() => {
		if (pendingSelectedColor) {
			onChange(pendingSelectedColor);
			setPendingSelectedColor(null);
			onClose && onClose();
		}
	}, [onChange, onClose, pendingSelectedColor]);

	const handleResetToGlobalColor = useCallback(() => {
		onResetToGlobal && onResetToGlobal();
		onClose && onClose();
	}, [onClose, onResetToGlobal]);

	const instructions = formatMessage(messages.additionalInstructionsForSelectingColorOptions);

	return (
		<ColorPaletteMenuWrapper
			$cols={cols}
			data-testid={dataTestIds.colorPaletteMenuWrapper}
			aria-label={`${fullLabel} ${instructions}`}
			role="radiogroup"
		>
			<ColorPaletteContainer>
				{palette.map(({ label: optionLabel, value }) => (
					<ColorCardWrapper key={value}>
						<ColorCard
							label={optionLabel}
							value={value}
							selected={selectedValueInPalette ? value === selectedValueInPalette.value : false}
							onClick={handleClick}
							onKeyDown={shouldUpdateCustomColorWithConfirmation ? handleKeyDown : onChange}
						/>
					</ColorCardWrapper>
				))}
			</ColorPaletteContainer>

			{informationMessage && (
				<Box xcss={sectionStyles}>
					<Divider />
					<SectionMessage appearance="information">{informationMessage}</SectionMessage>
				</Box>
			)}

			{isResetToGlobalAllowed && !isCustomColor && (
				<Box xcss={sectionStyles}>
					<Divider />
					<Tooltip position="bottom-start" content={resetButtonTooltipContent}>
						<Button
							iconBefore={RefreshIcon}
							appearance="subtle"
							onClick={handleResetToGlobalColor}
							testId="software-board-settings-color-picker.ui.color-picker.color-palette-menu.reset-to-global"
						>
							{formatMessage(messages.resetToGlobalButtonLabel)}
						</Button>
					</Tooltip>
				</Box>
			)}

			{isCustomColor && isShowingCustomColor && (
				<Box xcss={customColorWrapperStyles}>
					<Divider />
					<Heading size="xxsmall" as="div">
						{formatMessage(messages.customColorLabel)}
					</Heading>

					<CustomColorCardWrapper>
						<ColorCard
							label={formatMessage(messages.optionLabelForCustomColor, {
								customColor: selectedColor,
							})}
							value={selectedColor}
							selected={!shouldUpdateCustomColorWithConfirmation || !pendingSelectedColor}
						/>
					</CustomColorCardWrapper>

					<SectionMessage appearance="warning">
						<Text as="p">{formatMessage(messages.warningForCustomColor)}</Text>
					</SectionMessage>

					{shouldUpdateCustomColorWithConfirmation && (
						<Flex
							direction="row"
							xcss={actionButtonsWrapperStyles}
							justifyContent={isResetToGlobalAllowed ? 'space-between' : 'end'}
						>
							{isResetToGlobalAllowed && (
								<Tooltip position="bottom-start" content={resetButtonTooltipContent}>
									<Button
										iconBefore={RefreshIcon}
										appearance="subtle"
										onClick={handleResetToGlobalColor}
										testId="software-board-settings-color-picker.ui.color-picker.color-palette-menu.reset"
									>
										{formatMessage(messages.resetButtonLabel)}
									</Button>
								</Tooltip>
							)}
							<Flex gap="space.100" justifyContent="end" direction="row">
								<Button
									appearance="subtle"
									onClick={onClose}
									testId="software-board-settings-color-picker.ui.color-picker.color-palette-menu.cancel"
								>
									{formatMessage(messages.cancelButtonLabel)}
								</Button>
								<Button
									isDisabled={!pendingSelectedColor}
									appearance="primary"
									onClick={handleSubmitPendingColor}
									testId="software-board-settings-color-picker.ui.color-picker.color-palette-menu.update"
								>
									{formatMessage(messages.updateButtonLabel)}
								</Button>
							</Flex>
						</Flex>
					)}
				</Box>
			)}
		</ColorPaletteMenuWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorPaletteContainer = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	padding: 0,
	transform: `translateX(${token('space.negative.050')})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorPaletteMenuWrapper = styled.div<{ $cols: number }>({
	position: 'relative',
	margin: 0,

	backgroundColor: token('elevation.surface.overlay'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: ({ $cols }) => `${getWidth($cols)}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCardWrapper = styled.div({
	display: 'flex',
	marginTop: token('space.025'),
	marginRight: token('space.025'),
	marginBottom: token('space.025'),
	marginLeft: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${COLOR_CARD_SIZE}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomColorCardWrapper = styled.div({
	marginTop: token('space.025'),
	marginRight: 0,
	marginBottom: token('space.100'),
	marginLeft: 0,
	transform: `translateX(${token('space.negative.025')})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${COLOR_CARD_SIZE}px`,
});

const customColorWrapperStyles = xcss({
	marginRight: 'space.100',
	marginBottom: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `2px solid ${token('color.border')}`,
	marginTop: token('space.100'),
	marginBottom: token('space.150'),
});

const actionButtonsWrapperStyles = xcss({
	marginTop: 'space.300',
});

const sectionStyles = xcss({
	marginRight: 'space.100',
	marginBottom: 'space.050',
});
