import type { CardColorsConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { CardColorsConfigWithCardColors, State } from '../../../common/types/index.tsx';

export type InitStateAction = () => Action<State, CardColorsConfig, void>;

export const initState =
	() =>
	(
		{ setState, getState }: StoreActionApi<State>,
		cardColorsConfig: CardColorsConfig | CardColorsConfigWithCardColors,
	) => {
		setState({
			...getState(),
			...cardColorsConfig,
			isInitialised: true,
			isCMPBoard: true,
		});
	};
