import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { ConfigDisabledSection } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/config-disabled-section/index.tsx';
import { PageHeader } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/page-header/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import { BOARD_SETTINGS_CREATE_SWIMLANE_MODAL_ID } from '../../common/constants/modals.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSW,
	BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSW_ANALYTICS_ID,
	BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSM,
	BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSM_ANALYTICS_ID,
} from '../../common/constants/swimlanes.tsx';
import { useCreateSwimlaneHandler } from '../../controllers/handlers/use-create-swimlane-handler/index.tsx';
import { useDeleteSwimlaneHandler } from '../../controllers/handlers/use-delete-swimlane-handler/index.tsx';
import { useEditSwimlaneHandler } from '../../controllers/handlers/use-edit-swimlane-handler/index.tsx';
import { useRankSwimlaneHandler } from '../../controllers/handlers/use-rank-swimlane-handler/index.tsx';
import { useSwimlaneStrategyTransition } from '../../controllers/handlers/use-swimlanes-strategy-transition/index.tsx';
import {
	useIsInitialised,
	useIsCMPBoard,
	useRapidViewId,
} from '../../controllers/selectors/board/index.tsx';
import { useCanEdit } from '../../controllers/selectors/permissions/index.tsx';
import { useViewSwimlanesSettingsExperienceTracker } from '../../services/experience-tracker/index.tsx';
import messages from './messages.tsx';
import { SwimlaneModals } from './modals/index.tsx';
import { SwimlaneStrategyContent } from './strategy-content/index.tsx';
import { SwimlaneStrategyField } from './swimlane-strategy-field/index.tsx';

export const SwimlanesSettingsContainer = () => {
	const { formatMessage } = useIntl();

	const [, { on: openCreateModal }] = useSwitchModals(BOARD_SETTINGS_CREATE_SWIMLANE_MODAL_ID);

	const [rapidViewId] = useRapidViewId();
	const [isCMPBoard] = useIsCMPBoard();
	const isJSMBoard = useIsJSMBoard();
	const [isInitialised] = useIsInitialised();

	const { fireExperienceSuccess } = useViewSwimlanesSettingsExperienceTracker({
		isClassic: isCMPBoard,
	});

	const { transition: swimlaneStrategyTransition, requestSwimlaneStrategyUpdate } =
		useSwimlaneStrategyTransition(rapidViewId);

	const { createQueryValidationErrorMessage, handleItemCreateSubmit } = useCreateSwimlaneHandler();

	const { editQueryValidationErrorMessage, itemToEdit, handleItemEdit, handleItemEditSubmit } =
		useEditSwimlaneHandler();

	const { itemToDelete, isDeleteSubmitting, handleItemDelete, handleItemDeleteConfirm } =
		useDeleteSwimlaneHandler();

	const handleRank = useRankSwimlaneHandler();

	useEffect(() => {
		// State initialisation is now finished so we mark view experience as success
		if (isInitialised) {
			fireExperienceSuccess({ isClassic: isCMPBoard });
		}
	}, [isInitialised, fireExperienceSuccess, isCMPBoard]);

	const [canEdit] = useCanEdit();

	const isSwimlanesTabOpen = useIsBoardSettingsTabOpen(
		boardSettingsQueryConfigParamValues.swimlanes,
	);

	return isSwimlanesTabOpen ? (
		<div data-testid={dataTestIds.app}>
			<Header data-testid={dataTestIds.appHeader}>
				<PageHeader
					title={formatMessage(messages.title)}
					description={
						<>
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.descriptionIssueTermRefresh
									: messages.description,
							)}{' '}
							<InProductHelpButton
								articleId={
									isJSMBoard
										? BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSM
										: BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSW
								}
								articleAnalyticsId={
									isJSMBoard
										? BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSM_ANALYTICS_ID
										: BOARD_SETTINGS_ARTICLE_ID_SWIMLANES_JSW_ANALYTICS_ID
								}
								appearance="link"
							>
								{formatMessage(messages.inProductHelp)}
							</InProductHelpButton>
							.
						</>
					}
				/>
				{!canEdit && <ConfigDisabledSection />}
				<SwimlaneStrategyField
					transition={swimlaneStrategyTransition}
					onChange={requestSwimlaneStrategyUpdate}
				/>
			</Header>
			<SwimlaneStrategyContent
				onChange={requestSwimlaneStrategyUpdate}
				transition={swimlaneStrategyTransition}
				handleEdit={handleItemEdit}
				handleDelete={handleItemDelete}
				handleRank={handleRank}
				openCreateModal={openCreateModal}
			/>
			<SwimlaneModals
				canEdit={canEdit}
				onCreateSubmit={handleItemCreateSubmit}
				createQueryValidationErrorMessage={createQueryValidationErrorMessage}
				itemToEdit={itemToEdit}
				editQueryValidationErrorMessage={editQueryValidationErrorMessage}
				onEditSubmit={handleItemEditSubmit}
				itemToDelete={itemToDelete}
				onDeleteSubmit={handleItemDeleteConfirm}
				isDeleteSubmitting={isDeleteSubmitting}
			/>
		</div>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.300'),
	paddingBottom: token('space.400'),
});
