import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import EditorDoneIcon from '@atlaskit/icon/core/migration/check-mark--editor-done';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import { COLOR_CARD_SIZE } from '../../../../common/constants/index.tsx';

export type Props = {
	value: string;
	label: string;
	onClick?: (value: string) => void;
	onKeyDown?: (value: string) => void;
	selected?: boolean;
};

export const ColorCard = ({ value, label, selected, onClick, onKeyDown }: Props) => {
	const handleClick = useCallback(
		(_: MouseEvent<HTMLDivElement>) => {
			if (onClick) {
				onClick(value);
			}
		},
		[onClick, value],
	);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLElement>) => {
			const { key } = e;

			if (onKeyDown && (key === 'Enter' || key === ' ')) {
				e.preventDefault();
				onKeyDown(value);
			}
		},
		[onKeyDown, value],
	);

	return (
		<Tooltip content={label}>
			<ColorCardOption
				data-testid={dataTestIds.cardColorOption}
				onClick={handleClick}
				role="radio"
				aria-checked={selected}
				aria-label={label}
				tabIndex={0}
				onKeyDown={handleKeyDown}
			>
				<ColorCardContent
					data-testid="software-board-settings-color-picker.ui.color-picker.color-palette-menu.color-card.color-card-content"
					color={value || 'transparent'}
				>
					{selected && (
						<ColorCardContentCheckMark>
							<EditorDoneIcon spacing="spacious" color={token('color.icon.inverse')} label="" />
						</ColorCardContentCheckMark>
					)}
				</ColorCardContent>
			</ColorCardOption>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCardOption = styled.div({
	display: 'inline-block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${COLOR_CARD_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${COLOR_CARD_SIZE}px`,
	borderWidth: '2px',
	borderStyle: 'solid',
	boxSizing: 'border-box',
	borderRadius: `${token('space.075')}`,
	transition: 'border-color 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	backgroundColor: 'transparent',
	padding: 0,
	cursor: 'pointer',
	outline: 'none',
	borderColor: 'transparent',
	'&:hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: `${token('color.border.focused')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCardContent = styled.div<{ color: string }>({
	position: 'absolute',
	top: '1px',
	left: '1px',
	width: token('space.300'),
	height: token('space.300'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ color }) => color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: `inset 0px 0px 0px 1px ${token('color.background.inverse.subtle')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorCardContentCheckMark = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	margin: '1px',
});
