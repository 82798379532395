import React, { useEffect, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import {
	useIsInitialised,
	useIsScrumBoard,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useCanEdit } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import { CompletedIssuesSection } from '@atlassian/jira-software-board-settings-general-completed-issues-settings/src/ui/completed-issues-section/index.tsx';
import {
	BoardFilterSettingsFields,
	BoardFilterSettingsFieldsWithNoMargin,
} from '@atlassian/jira-software-board-settings-general-filter-settings/src/ui/index.tsx';
import { GeneralSettingFields } from '@atlassian/jira-software-board-settings-general-settings/src/ui/index.tsx';
import { BoardSubFilterSettingsFields } from '@atlassian/jira-software-board-settings-general-sub-filter-settings/src/ui/index.tsx';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { ConfigDisabledSection } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/config-disabled-section/index.tsx';
import { PageHeader } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/page-header/index.tsx';
import { useViewGeneralSettingsExperienceTracker } from '../../services/experience-tracker/index.tsx';
import messages from './messages.tsx';

export const GeneralSettings = () => {
	const isInitialised = useIsInitialised();
	const { formatMessage } = useIntl();
	const canEdit = useCanEdit();
	const isScrumBoard = useIsScrumBoard();

	const { fireExperienceSuccess } = useViewGeneralSettingsExperienceTracker({
		isClassic: true,
	});

	const isGeneralTabOpen = useIsBoardSettingsTabOpen(boardSettingsQueryConfigParamValues.filter);

	useEffect(() => {
		if (isInitialised) {
			// State initialisation is now finished so we mark view experience as success
			fireExperienceSuccess({ isClassic: true });
		}
	}, [isInitialised, fireExperienceSuccess]);

	const { data } = useJiraServiceManagementProjectNavigation();
	const isJSMBoardEnabled = !!data?.boardInfo.enabled;
	const isJSMBoard = useIsJSMBoard();

	// if it's JSM board and board feature is disabled we only show the filter setting for calendar config
	if (isGeneralTabOpen && isJSMBoard && !isJSMBoardEnabled) {
		return (
			<Wrapper>
				<Stack xcss={headerStyles}>
					<PageHeader
						title={formatMessage(messages.pageTitle)}
						description={formatMessage(messages.pageDescription, {
							strong: (chunks: ReactNode[]) => (
								<Inline as="span" xcss={asteriskStyles}>
									{chunks}
								</Inline>
							),
						})}
					/>
				</Stack>
				{!canEdit && <ConfigDisabledSection />}
				<BoardFilterSettingsFieldsWithNoMargin />
			</Wrapper>
		);
	}

	return isGeneralTabOpen ? (
		<Wrapper>
			<Stack xcss={headerStyles}>
				<PageHeader
					title={formatMessage(messages.pageTitle)}
					description={formatMessage(messages.pageDescription, {
						strong: (chunks: ReactNode[]) => (
							<Inline as="span" xcss={asteriskStyles}>
								{chunks}
							</Inline>
						),
					})}
				/>
			</Stack>
			{!canEdit && <ConfigDisabledSection />}
			<GeneralSettingFields />
			<BoardFilterSettingsFields />
			<BoardSubFilterSettingsFields />
			{!isScrumBoard && <CompletedIssuesSection />}
		</Wrapper>
	) : null;
};

const headerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& label': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		paddingLeft: '0 !important',
	},
});

const asteriskStyles = xcss({
	color: 'color.text.danger',
	display: 'inline-flex',
});
