import React, { useCallback, useState, useMemo } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { ErrorAutoDismissFlag } from '@atlassian/jira-flags/src/common/ui/components';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import {
	useRemoveEditAccess,
	useUpdateBoardAdmins,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/main.tsx';
import { useBoardAdmins } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useIsCMPBoard,
	useRapidViewId,
	useWarnBeforeEditingOwner,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useUserConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/user/index.tsx';
import type {
	BoardAdmins,
	UserGroupKey,
	UserKey,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/board-admin.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useFetchAvailableBoardAdmins } from '../../services/fetch-available-board-admins/index.tsx';
import type { FetchBoardAdminsQuery } from '../../services/fetch-available-board-admins/types.tsx';
import { useFetchUserWithGroups } from '../../services/fetch-user-with-groups/index.tsx';
import { useUpdateBoardAdmins as useUpdateBoardAdminsService } from '../../services/update-remote-board-admins/index.tsx';
import type { UpdateBoardAdminPayload } from '../../services/update-remote-board-admins/types.tsx';
import messages from './messages.tsx';
import type { SelectOptionType } from './types.tsx';

const useUserWillRetainAccess = () => {
	const { accountId } = useUserConfig();
	const warnBeforeEditingOwner = useWarnBeforeEditingOwner();
	const { commit: fetchUserWithGroups } = useFetchUserWithGroups();

	const userWillRetainAccess = useCallback(
		async (selectedOptions: readonly SelectOptionType[]): Promise<boolean | null> => {
			if (!warnBeforeEditingOwner) return true;
			if (selectedOptions == null) return null;

			const userWithGroups = await fetchUserWithGroups({ accountId });
			if (userWithGroups == null) return null;

			const userGroups = new Set<string | number>(userWithGroups.groups.items.map((g) => g.name));

			for (const { group, value } of selectedOptions) {
				if (group === 'userKeys' && value === accountId) return true;
				if (group === 'groupKeys' && userGroups.has(value)) return true;
			}

			return false;
		},
		[accountId, fetchUserWithGroups, warnBeforeEditingOwner],
	);

	return { userWillRetainAccess };
};

const useShowSuccessFlag = () => {
	const isCMPBoard = useIsCMPBoard();
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();

	return useCallback(() => {
		showFlag((props) => (
			<SuccessFlagWithBoardLink
				isCMPBoard={isCMPBoard}
				title={formatMessage(messages.boardAdminsUpdatedSuccessfully)}
				messageId="software-board-settings-general-settings.ui.board-administrator-field.success-flag-with-board-link"
				messageType="transactional"
				{...props}
			/>
		));
	}, [showFlag, formatMessage, isCMPBoard]);
};

const useShowErrorFlag = () => {
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();

	return useCallback(
		(title: MessageDescriptor, description: MessageDescriptor) => {
			showFlag((props) => (
				<ErrorAutoDismissFlag
					title={formatMessage(title)}
					description={formatMessage(description)}
					messageId="software-board-settings-general-settings.ui.board-administrator-field.error-auto-dismiss-flag"
					messageType="transactional"
					{...props}
				/>
			));
		},
		[showFlag, formatMessage],
	);
};

export const useUpdateBoardAdminsUtils = () => {
	const boardAdmins = useBoardAdmins();
	const initialOptions = useMemo<readonly SelectOptionType[] | null>(() => {
		if (boardAdmins == null) return null;
		const userOptions = mapToSelectOptions(boardAdmins.userKeys, 'userKeys');
		const groupOptions = mapToSelectOptions(boardAdmins.groupKeys, 'groupKeys');
		return userOptions.concat(groupOptions);
	}, [boardAdmins]);

	const [showModal, setShowModal] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState(initialOptions);

	const rapidViewId = useRapidViewId();
	const removeEditAccess = useRemoveEditAccess();
	const showErrorFlag = useShowErrorFlag();
	const showSuccessFlag = useShowSuccessFlag();
	const updateBoardAdmins = useUpdateBoardAdmins();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();
	const { userWillRetainAccess } = useUserWillRetainAccess();
	const { commit: commitUpdateBoardAdmins } = useUpdateBoardAdminsService(rapidViewId);

	const onUpdate = useCallback(
		async (confirmation: boolean) => {
			if (initialOptions === selectedOptions) {
				setShowModal(false);
				return;
			}

			if (selectedOptions == null || selectedOptions.length === 0) {
				setShowModal(false);
				setSelectedOptions(initialOptions);
				showErrorFlag(messages.unableToRemoveAdmin, messages.boardNeedsAtLeastOneAdmin);
				return;
			}

			const retainsAccess = (await userWillRetainAccess(selectedOptions)) ?? true;

			if (!retainsAccess && !confirmation) {
				setShowModal(true);
				return;
			}

			const admins: UpdateBoardAdminPayload = { userKeys: [], groupKeys: [] };
			selectedOptions.forEach((option) => {
				admins[option.group].push(option.value.toString());
			});

			const result = await commitUpdateBoardAdmins({
				boardAdmins: admins,
			});

			setShowModal(false);

			if (result == null || result.errors) {
				showErrorFlag(messages.unableToUpdate, messages.updateBoardAdminsFailureDescription);
				fireTrackEvent('updateBoardAdmins failed');
				setSelectedOptions(initialOptions);
				return;
			}

			if (!retainsAccess) {
				removeEditAccess();
			}

			updateBoardAdmins(result);
			showSuccessFlag();
			fireTrackEvent('updateBoardAdmins succeeded');
		},
		[
			fireTrackEvent,
			selectedOptions,
			updateBoardAdmins,
			commitUpdateBoardAdmins,
			userWillRetainAccess,
			removeEditAccess,
			initialOptions,
			showSuccessFlag,
			showErrorFlag,
		],
	);

	return {
		showModal,
		selectedOptions,
		onChange: setSelectedOptions,
		onSelectSubmit: useCallback(() => onUpdate(false), [onUpdate]),
		onModalSubmit: useCallback(() => onUpdate(true), [onUpdate]),
		onModalDismiss: useCallback(() => {
			setSelectedOptions(initialOptions);
			setShowModal(false);
		}, [initialOptions]),
	};
};

export const useAvailableBoardAdmins = () => {
	const [availableBoardAdmins, setAvailableBoardAdmins] = useState<BoardAdmins>();
	const { loading, commit: fetchBoardAdmins } = useFetchAvailableBoardAdmins();

	const fetchAvailableBoardAdmins = useCallback(
		async ({ query }: FetchBoardAdminsQuery) => {
			const result = await fetchBoardAdmins({ query });
			if (result) {
				setAvailableBoardAdmins({
					userKeys: result.users,
					groupKeys: result.groups,
				});
			}
		},
		[fetchBoardAdmins, setAvailableBoardAdmins],
	);

	return { loading, availableBoardAdmins, fetchAvailableBoardAdmins };
};

export const mapToSelectOptions = (
	options: UserKey[] | UserGroupKey[],
	group: SelectOptionType['group'],
) =>
	options.map(
		(option): SelectOptionType => ({
			label: option.displayName,
			value: option.key,
			group,
		}),
	);
