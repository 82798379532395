import React, { useCallback, useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const ConfirmationModal = ({
	title,
	description,
	onSubmit,
	onCancel,
}: {
	title: React.ReactNode;
	description: React.ReactNode;
	onSubmit: () => Promise<void>;
	onCancel: () => void;
}) => {
	const [submitting, setSubmitting] = useState(false);
	const onConfirm = useCallback(async () => {
		setSubmitting(true);
		await onSubmit();
		setSubmitting(false);
	}, [onSubmit]);

	return (
		<ModalTransition>
			<ModalDialog
				onClose={onCancel}
				shouldReturnFocus
				messageId="software-board-settings-general-settings.common.ui.confirmation-modal.modal-dialog"
				messageType="transactional"
			>
				<ModalHeader>
					<ModalTitle appearance="warning">{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>{description}</ModalBody>
				<ModalFooter>
					<Button isDisabled={submitting} appearance="subtle" onClick={onCancel}>
						<FormattedMessage {...messages.cancel} />
					</Button>
					<LoadingButton
						isLoading={submitting}
						appearance="primary"
						onClick={onConfirm}
						type="submit"
					>
						<FormattedMessage {...messages.confirm} />
					</LoadingButton>
				</ModalFooter>
			</ModalDialog>
		</ModalTransition>
	);
};
