import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { ConfigDisabledSection } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/config-disabled-section/index.tsx';
import { useIsInitialised } from '../../controllers/selectors/board/index.tsx';
import { useCanEdit } from '../../controllers/selectors/permissions/index.tsx';
import { useViewEstimationSettingsExperienceTracker } from '../../services/experience-tracker/index.tsx';
import { EstimationMethod } from './estimation-method/index.tsx';
import { Header } from './header/index.tsx';
import { TimeTracking } from './time-tracking/index.tsx';

export const EstimationSettingsContainer = () => {
	const [isInitialised] = useIsInitialised();
	const [canEdit] = useCanEdit();

	const { fireExperienceSuccess } = useViewEstimationSettingsExperienceTracker({
		isClassic: true,
	});

	useEffect(() => {
		if (isInitialised) {
			// State initialisation is now finished so we mark view experience as success
			fireExperienceSuccess({ isClassic: true });
		}
	}, [isInitialised, fireExperienceSuccess]);

	const isEstimationTabOpen = useIsBoardSettingsTabOpen(
		boardSettingsQueryConfigParamValues.estimation,
	);

	return isEstimationTabOpen ? (
		<Container>
			<Header />
			{!canEdit && <ConfigDisabledSection />}
			<EstimationMethod isDisabled={!canEdit} />
			<TimeTracking isDisabled={!canEdit} />
		</Container>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	gridTemplateRow: 'repeat(3, auto)',
	gridGap: token('space.500'),
});
