import type { FlexibleNomenclatureData } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/global-config.tsx';
import type { SwimlanesConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export type InitStateAction = () => Action<State, SwimlanesConfig & FlexibleNomenclatureData, void>;

export const initState =
	() =>
	(
		{ setState, getState }: StoreActionApi<State>,
		swimlanesConfig: SwimlanesConfig & FlexibleNomenclatureData,
	) => {
		setState({
			...getState(),
			...swimlanesConfig,
			swimlanes: swimlanesConfig.swimlanes.filter(({ isDefault }) => !isDefault),
			defaultSwimlane: swimlanesConfig.swimlanes.find(({ isDefault }) => isDefault) || null,
			isInitialised: true,
			isCMPBoard: true,
		});
	};
