import React, { useCallback, useEffect, useMemo } from 'react';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { useBoardId } from '@atlassian/jira-board-settings-common/src/common/utils/use-board-id/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { BoardSettingsEstimatesSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { boardSettingsQueryConfigParamValues } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { useIsBoardSettingsTabOpen } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-board-settings-tab-open/index.tsx';
import { useOnce } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-once/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { APP_BOUNDARY_ID, APP_SOURCE_NAME, APP_TEAM_NAME } from './common/constants/app.tsx';
import { fetchError } from './common/ui/error/index.tsx';
import { dateFieldsPageEntryPoint } from './entrypoint.tsx';
import { useViewDateFieldsSettingsExperienceTracker } from './services/experience-tracker/index.tsx';

export const DateFieldsSettingsPage = () => {
	const boardId = useBoardId();
	const { showFlag } = useFlagsService();
	const { data: project } = useProjectContext();
	const cloudId = useCloudId();
	const activationId = useActivationId();

	// https://developer.atlassian.com/platform/atlassian-resource-identifier/resource-owners/registry/#ati%3Acloud%3Ajira%3Aview
	const viewId = `ari:cloud:jira:${cloudId}:view/activation/${activationId}/board/${boardId}`;
	const projectARI = `ari:cloud:jira:${cloudId}:project/${String(project?.projectId)}`;
	const boardAri = JiraSoftwareBoardAri.create({
		siteId: cloudId,
		boardId: String(boardId),
	}).toString();

	const entrypointParams = useMemo(
		() => ({
			scenario: boardAri,
			projectARIs: [projectARI],
			configuration: {
				viewId,
			},
			includeUserPermissions: fg('jsd_shield_weekly_view_fast_follow'),
		}),
		[boardAri, projectARI, viewId],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		dateFieldsPageEntryPoint,
		entrypointParams,
	);

	const { fireExperienceStart, fireExperienceError } = useViewDateFieldsSettingsExperienceTracker({
		isClassic: true,
	});

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			fireExperienceError(error);
			showFlag(fetchError());

			return <JSErrorPageAsync error={error} isFullPage={false} />;
		},
		[fireExperienceError, showFlag],
	);

	useOnce(fireExperienceStart);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
		return () => {
			entryPointActions.unload();
		};
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id={APP_BOUNDARY_ID}
			packageName={APP_SOURCE_NAME}
			teamName={APP_TEAM_NAME}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={{}}
			fallback={<BoardSettingsEstimatesSkeleton />}
			errorFallback={errorFallback}
		/>
	);
};

export const PageGate = () => {
	const isDateFieldsTabOpen = useIsBoardSettingsTabOpen(
		boardSettingsQueryConfigParamValues.dateFields,
	);

	// Always rerender when the tab is active, else don't render anything
	if (!isDateFieldsTabOpen) {
		return null;
	}

	return <DateFieldsSettingsPage />;
};
