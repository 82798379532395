// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getCanEdit = (state: State): boolean => state.canEdit;

export const useCanEdit = createHook(Store, {
	selector: getCanEdit,
});
