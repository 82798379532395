import type { EstimationStatisticConfig } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/estimation.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export type InitStateAction = () => Action<State, EstimationStatisticConfig, void>;

export const initState =
	() =>
	(
		{ setState, getState }: StoreActionApi<State>,
		estimationStatisticConfig: EstimationStatisticConfig,
	) => {
		setState({
			...getState(),
			...estimationStatisticConfig,
			isInitialised: true,
		});
	};
