import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Inline, Stack } from '@atlaskit/primitives';
import { RadioGroup } from '@atlaskit/radio';
import type { OptionsPropType } from '@atlaskit/radio/types';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import {
	SPRINT_DATES_SCHEDULING,
	START_AND_DUE_DATE_SCHEDULING,
} from '../../../../common/constants/timeline.tsx';
import { useUpdateChildIssueSchedulingHandler } from '../../../../controllers/handlers/update-child-issue-scheduling-handler/index.tsx';
import { useUpdateChildPlanningHandler } from '../../../../controllers/handlers/update-child-planning-handler/index.tsx';
import { useCanEdit } from '../../../../controllers/selectors/permissions/index.tsx';
import { useIsSprintSupportEnabled } from '../../../../controllers/selectors/timeline/index.tsx';
import messages from './messages.tsx';

export const IncludeChildLevelIssues = () => {
	const { formatMessage } = useIntl();

	const [canEdit] = useCanEdit();
	const [isSprintSupportEnabled] = useIsSprintSupportEnabled();

	const options = useMemo<OptionsPropType>(
		() => [
			{
				name: START_AND_DUE_DATE_SCHEDULING,
				value: formatMessage(messages.startAndDueDateScheduling),
				label: formatMessage(messages.startAndDueDateScheduling),
			},
			{
				name: SPRINT_DATES_SCHEDULING,
				value: formatMessage(messages.sprintDatesScheduling),
				label: formatMessage(messages.sprintDatesScheduling),
			},
		],
		[formatMessage],
	);

	const { updateChildPlanning, isEnabled } = useUpdateChildPlanningHandler();
	const { updateChildIssueScheduling, value } = useUpdateChildIssueSchedulingHandler(options);

	return (
		<Stack space="space.200">
			<Stack space="space.100">
				<Inline space="space.200" alignBlock="center" alignInline="start">
					<Heading as="h4" size="xsmall">
						<HeadingSpan>
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.childLevelIssuesToggleTitleIssueTermRefresh
									: messages.childLevelIssuesToggleTitle,
							)}
						</HeadingSpan>
					</Heading>
					<Toggle
						testId={dataTestIds.includeChildLevelIssuesToggle}
						label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.childLevelIssuesToggleLabelIssueTermRefresh
								: messages.childLevelIssuesToggleLabel,
						)}
						isChecked={isEnabled}
						onChange={updateChildPlanning}
						isDisabled={!canEdit}
					/>
				</Inline>
				<Description>
					{!isSprintSupportEnabled
						? formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.descriptionIssueTermRefresh
									: messages.description,
							)
						: formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.descriptionWithSprintInfoIssueTermRefresh
									: messages.descriptionWithSprintInfo,
							)}
				</Description>
			</Stack>
			{isEnabled && isSprintSupportEnabled && (
				<>
					<RadioGroup
						testId={dataTestIds.includeChildLevelIssuesRadioGroup}
						options={options}
						value={value}
						onChange={updateChildIssueScheduling}
						isDisabled={!canEdit}
					/>
					<Description>{formatMessage(messages.scheduleOptionsDescription)}</Description>
				</>
			)}
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingSpan = styled.span({
	color: token('color.text.subtle'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	margin: 0,
});
