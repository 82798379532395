import React from 'react';
import '@atlaskit/css-reset';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { BoardConfigSwitcher } from '@atlassian/jira-router-routes-software-classic-board-settings-switcher/src/index.tsx';
import { ServiceDeskDocumentTitle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/document-title/index.tsx';
import { useProjectContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/project-context/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';

export const ServicedeskBoardSettingsAppLayout = () => {
	const projectContext = useProjectContext();
	if (projectContext == null) {
		return null;
	}

	const projectName = projectContext.project.name;

	return (
		<UFOSegment name="servicedesk-board-settings">
			<MarkProductStart />
			<ServiceDeskAppBase appName={APP_NAMES.BOARD_SETTINGS}>
				<JSErrorBoundary
					id="spa-apps-servicedesk.board-settings"
					packageName="spa-apps-servicedesk-board-settings"
					teamName="jsd-shield"
				>
					<ServiceDeskDocumentTitle projectName={projectName} section="viewsSettings" />
					<BoardConfigSwitcher />
				</JSErrorBoundary>
			</ServiceDeskAppBase>
		</UFOSegment>
	);
};
