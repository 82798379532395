import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { LoadingButton } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { CurrentField } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import { useDeleteCardLayoutFieldHandler } from '../../../../controllers/handlers/delete-card-layout-field-handler/index.tsx';
import messages from './messages.tsx';

type DeleteFieldButtonProps = {
	field: CurrentField;
};

export const DeleteFieldButton = ({ field }: DeleteFieldButtonProps) => {
	const { formatMessage } = useIntl();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const { commitFieldDelete, isDeleteSubmitting } = useDeleteCardLayoutFieldHandler(field.mode);

	const handleDeleteClick = useCallback(() => {
		commitFieldDelete(field);
		fg('jsw_due_date_metrics')
			? fireTrackEvent('boardCardLayout deleteField', {
					fieldName: field.name,
					fieldMode: field.mode,
				})
			: fireTrackEvent('boardCardLayout deleteField');
	}, [commitFieldDelete, field, fireTrackEvent]);

	return (
		<DeleteFieldButtonWrapper>
			<LoadingButton
				onClick={handleDeleteClick}
				aria-label={formatMessage(messages.deleteButtonAriaLabel, {
					fieldName: field.name,
				})}
				isLoading={isDeleteSubmitting}
				testId={dataTestIds.tableRowDelete}
			>
				{formatMessage(messages.delete)}
			</LoadingButton>
		</DeleteFieldButtonWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteFieldButtonWrapper = styled.div({
	marginRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(> &)': {
		width: token('space.500'),
		borderBottom: `${token('color.border')} 1px solid`,
	},
});
