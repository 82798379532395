import React from 'react';
import DragHandleVerticalIcon from '@atlaskit/icon/core/migration/drag-handle-vertical--drag-handler';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const ReorderHeader = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingInlineStart="space.150">
			<DragHandleVerticalIcon
				LEGACY_size="small"
				label={formatMessage(messages.tableHeadReorderLabel)}
			/>
		</Box>
	);
};
