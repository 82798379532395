import React, { Component } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { OPEN_SHARE, PUBLIC_SHARE } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { Option, SharePermissionOrPrivate, ShareType } from '../../model/types.tsx';
import ShareList from './sharee-list/view.tsx';
import ShareePicker from './sharee-picker/index.tsx';

export type Props = {
	readonly isShareScopeLoading: boolean;
	readonly isEditorList: boolean;
	readonly shareeOptions: Option[];
	readonly type: ShareType | undefined;
	readonly sharePermissionRows: SharePermissionOrPrivate[];
	readonly customMessage?: string | undefined;
	readonly errorMessage?: string | undefined;
	readonly duplicatedIndex: number | undefined;
	readonly ariaLabel?: string;
	readonly ariaLabelledby?: string;
	readonly onRemove: (arg1: number, arg2: UIAnalyticsEvent) => void;
	readonly onAddSharePermission: (arg1: SharePermissionOrPrivate, arg2: UIAnalyticsEvent) => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class Access extends Component<Props> {
	static defaultProps = {
		isEditorList: false,
		type: undefined,
		duplicatedIndex: undefined,
		customMessage: undefined,
		errorMessage: undefined,
	};

	render() {
		const {
			isEditorList,
			isShareScopeLoading,
			type,
			shareeOptions,
			sharePermissionRows,
			duplicatedIndex,
			onRemove,
			onAddSharePermission,
			customMessage,
			errorMessage,
			ariaLabel,
			ariaLabelledby,
		} = this.props;

		const isOnlyPublicAndOrganization =
			shareeOptions.length === 2 &&
			shareeOptions.every((i) => i.value === OPEN_SHARE || i.value === PUBLIC_SHARE);

		return (
			<>
				{customMessage ? <CustomMessage>{customMessage}</CustomMessage> : <></>}
				<ShareePicker
					ariaLabel={ariaLabel}
					ariaLabelledby={ariaLabelledby}
					isEditorList={isEditorList}
					isShareScopeLoading={isShareScopeLoading}
					type={type}
					options={shareeOptions}
					onAddSharePermission={onAddSharePermission}
				/>
				{errorMessage && (
					<Box xcss={errorMessageContainerStyles}>
						<ErrorMessage>{errorMessage}</ErrorMessage>
					</Box>
				)}
				{isShareScopeLoading ? (
					<EmptyRow />
				) : (
					!!sharePermissionRows.length && (
						<ShareList
							isEditorList={isEditorList}
							sharePermissionRows={sharePermissionRows}
							duplicatedIndex={duplicatedIndex}
							forceHideRemoveButton={isOnlyPublicAndOrganization}
							onRemove={onRemove}
						/>
					)
				)}
			</>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyRow = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `2px solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 5 - 1}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomMessage = styled.div({
	font: token('font.body.small'),
	marginTop: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});

const errorMessageContainerStyles = xcss({
	paddingBottom: 'space.100',
});
