// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getFocusDirective = (state: State) => state.focusDirective;

export const useFocusDirective = createHook(Store, {
	selector: getFocusDirective,
});
