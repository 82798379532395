import type { CardLayoutConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createStore } from '@atlassian/react-sweet-state';
import { INITIAL_STATE } from '../common/constants/state.tsx';
import type { State } from '../common/types/index.tsx';
import { actions } from './actions/index.tsx';
import type { Actions } from './actions/types.tsx';

export const Store = createStore<State, Actions>({
	name: 'CardLayoutConfigState',
	initialState: INITIAL_STATE,
	actions,
});

export const CardLayoutConfigDataContainer = createContainer<State, Actions, CardLayoutConfigProps>(
	Store,
	{
		onInit: actions.initState,
	},
);
