import React, {
	type KeyboardEvent as ReactKeyBoardEvent,
	useCallback,
	useRef,
	useLayoutEffect,
} from 'react';
import { styled } from '@compiled/react';
import type { CustomTriggerProps } from '@atlaskit/dropdown-menu';
import FocusRing from '@atlaskit/focus-ring';
import DragHandleVerticalIcon from '@atlaskit/icon/core/migration/drag-handle-vertical--drag-handler';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import messages from './messages.tsx';

type Props = CustomTriggerProps<HTMLElement> & {
	name: string;
};

export const DragHandleButton = (props: Props) => {
	const dragHandleButtonRef = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();

	const { onClick: onDragHandleClick, triggerRef, isSelected } = props;
	const { name, ...triggerProps } = props;

	const ref = useMergeRefs(dragHandleButtonRef, triggerRef);

	// Need to useLayoutEffect to capture and prevent the keydown for drag handle button before DynamicTable capture it
	// This is important to support keyboard
	useLayoutEffect(() => {
		const handler = (e: KeyboardEvent) => {
			if (e.key !== ' ' && e.key !== 'Enter') {
				return;
			}

			// Only preventDefault if target matches the current button
			if (e.target === dragHandleButtonRef.current) {
				e.preventDefault();
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('keydown', handler, true);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handler, true);
		};
	}, []);

	const handkeKeyDown = useCallback(
		(e: ReactKeyBoardEvent<HTMLDivElement>) => {
			if (onDragHandleClick && (e.key === 'Enter' || e.key === ' ')) {
				e.stopPropagation();
				e.preventDefault();
				onDragHandleClick(e);
			}
		},
		[onDragHandleClick],
	);

	return (
		<FocusRing>
			{/* Dynamic Table does not allow drag event with interactive element so we use a div with role button for drag handle icon */}
			<DragHandleButtonContainer
				role="button"
				tabIndex={0}
				{...triggerProps}
				onKeyDown={handkeKeyDown}
				ref={ref}
				isDragContextMenuOpen={!!isSelected}
				data-testid={dataTestIds.queryListDragHandle}
			>
				<DragHandleVerticalIcon
					LEGACY_size="medium"
					spacing="spacious"
					label={formatMessage(messages.dragHandleLabel, {
						name,
					})}
				/>
			</DragHandleButtonContainer>
		</FocusRing>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandleButtonContainer = styled.div<{ isDragContextMenuOpen: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		backgroundColor: token('color.background.neutral.subtle.pressed'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isDragContextMenuOpen }) =>
		isDragContextMenuOpen
			? token('color.background.selected')
			: token('color.background.neutral.subtle'),

	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius.100', '3px'),
	paddingTop: token('space.0'),
	paddingRight: token('space.0'),
	paddingBottom: token('space.0'),
	paddingLeft: token('space.0'),
	width: 'max-content',
	border: 'none',
	cursor: 'grab',
	display: 'flex',
});
