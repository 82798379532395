import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button, { IconButton } from '@atlaskit/button/new';
import Form from '@atlaskit/form';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const ModalFormContainer = memo(
	({
		children,
		onCancel,
		isSubmitting,
	}: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		children: any;
		onCancel: () => void;
		isSubmitting: boolean;
	}) => (
		<ShortcutScope>
			<ModalDialog
				messageId="software-board-settings-query-list-config-common.common.ui.modal-form.modal-dialog"
				messageType="transactional"
				onClose={onCancel}
				shouldReturnFocus
				shouldCloseOnOverlayClick={!isSubmitting}
				shouldCloseOnEscapePress={!isSubmitting}
			>
				{children}
			</ModalDialog>
		</ShortcutScope>
	),
);

export interface ModalFormProps<T> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	title: React.ReactNode;
	description?: React.ReactNode;
	submitButtonLabel: string;
	onSubmit: (data: T) => void;
	onCancel: () => void;
	isDisabled?: boolean;
	isSubmitting?: boolean;
	testId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalForm = <T extends Record<string, any>>({
	children,
	title,
	description,
	submitButtonLabel,
	onSubmit,
	onCancel,
	isDisabled,
	isSubmitting = false,
	testId,
}: ModalFormProps<T>) => {
	const { formatMessage } = useIntl();

	const onSubmitImpl = useCallback(
		(value: T) => {
			onSubmit(value);
		},
		[onSubmit],
	);

	return (
		<ModalFormContainer onCancel={onCancel} isSubmitting={isSubmitting}>
			<Form<T> onSubmit={onSubmitImpl} isDisabled={isDisabled}>
				{({ formProps }) => (
					<form {...formProps} data-testid={testId}>
						<ModalHeader>
							<ModalTitle>{title}</ModalTitle>
							<IconButton
								appearance="subtle"
								aria-labelledby={formatMessage(messages.closeModal)}
								icon={CrossIcon}
								label={formatMessage(messages.closeModal)}
								isDisabled={isSubmitting}
								onClick={onCancel}
								testId="software-board-settings-query-list-config-common.common.ui.modal-form.close-button"
							/>
						</ModalHeader>
						<ModalBody>
							{description && <Description>{description}</Description>}
							{children}
						</ModalBody>
						<ModalFooter>
							<Button
								appearance="subtle"
								isDisabled={isSubmitting}
								onClick={onCancel}
								testId="software-board-settings-query-list-config-common.common.ui.modal-form.cancel-button"
							>
								{formatMessage(messages.cancel)}
							</Button>
							<Button
								isDisabled={isDisabled}
								isLoading={isSubmitting}
								appearance="primary"
								type="submit"
								testId="software-board-settings-query-list-config-common.common.ui.modal-form.submit-button"
							>
								{submitButtonLabel}
							</Button>
						</ModalFooter>
					</form>
				)}
			</Form>
		</ModalFormContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	paddingBottom: token('space.200'),
	color: token('color.text.subtle'),
});
