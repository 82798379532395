import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Field } from '@atlaskit/form';
import { type LoadingIndicatorProps, AsyncSelect } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEdit } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/permissions/index.tsx';
import { ConfirmationModal } from '../../common/ui/confirmation-modal/index.tsx';
import type { Location } from '../../services/fetch-board-location/index.tsx';
import messages from './messages.tsx';
import { LocationType } from './types.tsx';
import { useBoardLocations, useUpdateBoardLocation } from './utils.tsx';

const LoadingIndicator = (props: LoadingIndicatorProps<Location>) => <Spinner {...props} />;

export const BoardLocationField = () => {
	const getOptionValue = (option: Location) => option.id || '';

	const formatOptionLabel = (option: Location) => (
		<OptionContainer>
			<Avatar size="small" appearance="square" src={option.avatar} />
			<OptionLabel>{option.displayName}</OptionLabel>
		</OptionContainer>
	);

	const { formatMessage } = useIntl();
	const {
		boardLocation: currentBoardLocation,
		showModal,
		onModalSubmit,
		onModalDismiss,
		updateBoardLocation,
	} = useUpdateBoardLocation();
	const canEdit = useCanEdit();
	const { boardLocation, fetchBoardLocations } = useBoardLocations();

	const defaultValue: Location | null = useMemo(
		() =>
			boardLocation
				? {
						displayName: boardLocation.name,
						name: boardLocation.name,
						id:
							boardLocation.projectId !== null
								? boardLocation.projectId
								: boardLocation.userLocationId,
						avatar: boardLocation.avatarURI,
						key: boardLocation.projectId !== null ? LocationType.PROJECT : LocationType.USER,
					}
				: null,
		[boardLocation],
	);

	const loadOptions = (input: string) => fetchBoardLocations(input);

	const onValueSelect = useCallback(
		(option: Location) => {
			updateBoardLocation(option);
		},
		[updateBoardLocation],
	);

	return (
		<Container data-testid="software-board-settings-general-settings.ui.board-location-field.select">
			<Field
				id="board-location"
				label={formatMessage(messages.label)}
				name="boardLocation"
				isRequired
			>
				{() => (
					<AsyncSelect
						inputId="board-location"
						aria-label={
							boardLocation
								? formatMessage(messages.locationSelectAriaLabel, {
										name: boardLocation.name,
									})
								: formatMessage(messages.label)
						}
						isMulti={false}
						isDisabled={!canEdit}
						formatOptionLabel={formatOptionLabel}
						getOptionValue={getOptionValue}
						defaultValue={defaultValue}
						value={currentBoardLocation}
						cacheOptions
						components={{ LoadingIndicator }}
						loadOptions={loadOptions}
						onChange={(newOption) => newOption && onValueSelect(newOption)}
						defaultOptions
					/>
				)}
			</Field>
			{showModal && (
				<ConfirmationModal
					title={formatMessage(messages.confirmationModalTitle)}
					description={formatMessage(messages.confirmationModalDescription)}
					onCancel={onModalDismiss}
					onSubmit={onModalSubmit}
				/>
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	gridTemplateColumns: '350px',
	gridAutoFlow: 'row',
	gridAutoRows: 'auto',
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionLabel = styled.span({
	paddingLeft: token('space.100'),
	paddingBottom: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	borderRadius: '3px',
});
