import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { CurrentField } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-layout.tsx';
import { DeleteFieldButton } from './delete-field-button/index.tsx';
import messages from './messages.tsx';

const TableColumns = {
	Name: 'name',
	Actions: 'actions',
} as const;

export const useTableHeadAndRows = ({
	fields,
	isReadOnly,
}: {
	fields: CurrentField[];
	isReadOnly: boolean;
}) => {
	const { formatMessage } = useIntl();

	const head = useMemo(
		() => ({
			cells: [
				{
					key: TableColumns.Name,
					content: formatMessage(messages.nameHeader),
				},
				{
					key: TableColumns.Actions,
					content: formatMessage(messages.actionsHeader),
					width: 1,
					disabled: isReadOnly,
				},
			].filter((cell) => !cell.disabled),
		}),
		[formatMessage, isReadOnly],
	);

	const rows = useMemo(
		() =>
			fields.map((field) => ({
				key: String(field.id),
				label: field.name,
				cells: [
					{
						key: TableColumns.Name,
						content: <CellWrapper>{field.name}</CellWrapper>,
					},
					{
						key: TableColumns.Actions,
						content: <DeleteFieldButton field={field} />,
						disabled: isReadOnly,
					},
				].filter((cell) => !cell.disabled),
			})),
		[fields, isReadOnly],
	);

	return { head, rows };
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CellWrapper = styled.div({
	paddingTop: token('space.100'),
	paddingRight: 0,
	paddingBottom: token('space.100'),
	paddingLeft: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(&)': {
		borderBottom: `${token('color.border')} 1px solid`,
	},
});
