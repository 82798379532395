import type { CardLayoutConfigProps } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export type InitStateAction = () => Action<State, CardLayoutConfigProps, void>;

export const initState =
	() =>
	({ setState, getState }: StoreActionApi<State>, cardLayoutConfig: CardLayoutConfigProps) => {
		setState({
			...getState(),
			...cardLayoutConfig,
			isInitialised: true,
			isCMPBoard: true,
		});
	};
