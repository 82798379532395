import { useEffect, useRef, useMemo } from 'react';
import { VALIDATION_ERROR_NAME } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { type FlagMessage, useFlagsService } from '@atlassian/jira-flags';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { isClientNetworkError } from './utils.tsx';

type FailureType = 'FETCH_FAILURE' | 'UPDATE_FAILURE';

type ErrorCause = {
	error?: string;
	field?: string;
};

const createUseErrorFlagOnRequestFailure =
	(failureType: FailureType) =>
	({
		hasFailure,
		handleValidationError = false,
		errorTitle,
		errorMessage,
		error,
		validationErrorInstruction,
	}: Props) => {
		const { showFlag, dismissFlag } = useFlagsService();
		const errorFlagIds = useRef<string[]>([]);

		const titleMessage = useMemo(() => {
			if (errorTitle) {
				return errorTitle;
			}
			if (failureType === 'FETCH_FAILURE') {
				return messages.fetchFailureTitle;
			}

			if (failureType === 'UPDATE_FAILURE') {
				return messages.updateFailureTitle;
			}

			return '';
		}, [errorTitle]);

		useEffect(() => {
			if (hasFailure) {
				let failureMessage: FlagMessage | string = messages.requestFailureMessage;
				if (errorMessage && !isClientNetworkError(errorMessage)) {
					failureMessage = errorMessage;
				} else if (
					error &&
					handleValidationError &&
					error?.name === VALIDATION_ERROR_NAME &&
					typeof error?.cause === 'object'
				) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const cause = error?.cause as ErrorCause;

					if (cause.error) {
						failureMessage = `${cause.error}${
							validationErrorInstruction ? ` ${validationErrorInstruction}` : ''
						}`;
					}
				}

				const flagId = showFlag({
					type: 'error',
					title: titleMessage,
					description: failureMessage,
					isAutoDismiss: false,
					messageId: `software-board-settings-rapidboard-config-common.ui.flags.use-error-flag-on-request-failure.${failureType}`,
					messageType: 'transactional',
				});
				flagId && errorFlagIds.current.push(flagId);
				return;
			}

			errorFlagIds.current.forEach(dismissFlag);
			errorFlagIds.current = [];
		}, [
			hasFailure,
			handleValidationError,
			showFlag,
			dismissFlag,
			titleMessage,
			errorMessage,
			error,
			validationErrorInstruction,
		]);
	};

export const useErrorFlagOnUpdateFailure = createUseErrorFlagOnRequestFailure('UPDATE_FAILURE');

export const useErrorFlagOnFetchFailure = createUseErrorFlagOnRequestFailure('FETCH_FAILURE');
