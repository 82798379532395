import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import PersonIcon from '@atlaskit/icon/core/migration/person';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { UserShare } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { AvatarIllustrationContainer } from '../common/index.tsx';

type Props = {
	sharePermission: UserShare;
};

const UserName = (props: Props) => {
	const {
		sharePermission: { user },
	} = props;

	return (
		<>
			{!user.avatarUrl ? (
				<AvatarIllustrationContainer>
					<PersonIcon spacing="spacious" label="" />
				</AvatarIllustrationContainer>
			) : (
				<Avatar
					appearance={isVisualRefreshEnabled() ? 'circle' : 'square'}
					src={user.avatarUrl}
					size="small"
				/>
			)}
			<UserNameContainer>
				<UserNameWrapper>{user.displayName}</UserNameWrapper>
			</UserNameContainer>
		</>
	);
};

export default UserName;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UserNameContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RoleNameWrapper = styled.div({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UserNameWrapperOld = styled(RoleNameWrapper)({
	marginLeft: token('space.100'),
});

const UserNameWrapper = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_3'),
	RoleNameWrapper,
	UserNameWrapperOld,
);
