import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import { xcss, Box } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import {
	BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING,
	BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING_ANALYTICS_ID,
	NONE_TIME_TRACKING_STATISTIC_ID,
	TIME_ESTIMATE_TIME_TRACKING_STATISTIC_ID,
	SECTION_MAX_WIDTH,
} from '../../../common/constants/estimation.tsx';
import { useUpdateStatisticsHandler } from '../../../controllers/handlers/update-statistics-handler/index.tsx';
import { useCurrentTrackingStatistic } from '../../../controllers/selectors/estimation/index.tsx';
import messages from './messages.tsx';
import type { TimeTrackingProps } from './types.tsx';

export const TimeTracking = ({ isDisabled }: TimeTrackingProps) => {
	const { formatMessage } = useIntl();

	const { handleTimeTrackingUpdate } = useUpdateStatisticsHandler();
	const [{ isEnabled }] = useCurrentTrackingStatistic();
	const { showFlag } = useFlagService();

	const onToggleChange = useCallback(async () => {
		const result = await handleTimeTrackingUpdate({
			trackingStatisticId: isEnabled
				? NONE_TIME_TRACKING_STATISTIC_ID
				: TIME_ESTIMATE_TIME_TRACKING_STATISTIC_ID,
		});

		if (result) {
			showFlag((props) => (
				<SuccessFlagWithBoardLink
					isCMPBoard
					title={formatMessage(
						isEnabled
							? messages.successFlagTitleForDisabledTimeTracking
							: messages.successFlagTitleForEnabledTimeTracking,
					)}
					{...props}
				/>
			));
		}
	}, [handleTimeTrackingUpdate, isEnabled, showFlag, formatMessage]);

	const onHelpClick = useCallback(
		(_e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING_ANALYTICS_ID),
		[],
	);

	return (
		<Box xcss={boxStyles}>
			<HeadingContainer>
				<Heading as="h3" size="small">
					{formatMessage(messages.title)}
				</Heading>
				<Toggle
					label={formatMessage(messages.fieldLabel)}
					isChecked={isEnabled}
					onChange={onToggleChange}
					isDisabled={isDisabled}
				/>
			</HeadingContainer>
			<Box xcss={textStyles}>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description,
				)}
				<br />
				{formatMessage(messages.inProductHelp, {
					button: (chunks: React.ReactNode[]) => {
						return fg('jsw-board-settings-general-page-a11y-fixes') ? (
							<HelpPanelLink
								articleId={BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING}
								appearance="link"
								spacing="none"
								onClick={onHelpClick}
								fallbackComponent={null}
							>
								{chunks}
							</HelpPanelLink>
						) : (
							<InProductHelpButton
								articleId={BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING}
								articleAnalyticsId={BOARD_SETTINGS_ARTICLE_ID_TIME_TRACKING_ANALYTICS_ID}
								appearance="link"
							>
								{chunks}
							</InProductHelpButton>
						);
					},
				})}
			</Box>
		</Box>
	);
};

const boxStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${SECTION_MAX_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingContainer = styled.div({
	display: 'grid',
	gridGap: token('space.100'),
	gridTemplateColumns: 'auto 1fr',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > label': {
		margin: '0',
	},
});

const textStyles = xcss({
	marginTop: 'space.100',
});
