import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Stack, Inline, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import { DynamicTable } from '../../../common/ui/dynamic-table/index.tsx';
import { FilterDetailsHeader } from '../../../common/ui/filter-details-header/index.tsx';
import messages from './messages.tsx';

const PermissionContent = ({
	projectKey,
	canEdit,
	projectName,
}: {
	projectKey: string;
	canEdit: boolean;
	projectName: string;
}) => {
	const { formatMessage } = useIntl();

	const message = canEdit ? messages.viewPermissionLinkText : messages.noViewPermissionLinkText;
	return (
		<Button
			appearance="link"
			href={`/plugins/servlet/project-config/${projectKey}/permissions`}
			aria-label={formatMessage(messages.viewPermissionAriaLabel, { projectName })}
			isDisabled={!canEdit}
		>
			<FormattedMessage {...message} />
		</Button>
	);
};

const ProjectContent = ({ name, projectId }: { name: string; projectId: number }) => (
	<NameContent>
		<Avatar
			appearance="square"
			size="small"
			src={`/secure/projectavatar?pid=${projectId}&size=small`}
			name={name}
		/>
		<Inline>{name}</Inline>
	</NameContent>
);

const ProjectsIncluded = () => {
	const [{ queryProjects }] = useBoardFilterConfig();
	const isJSMBoard = useIsJSMBoard();
	const { formatMessage } = useIntl();

	const isMaxSupportShowingProjectsReached =
		queryProjects &&
		'isMaxSupportShowingProjectsReached' in queryProjects &&
		queryProjects.isMaxSupportShowingProjectsReached;

	const tableHead = {
		cells: [
			{
				key: 'name',
				content: (
					<Box xcss={projectHeaderStyles}>
						<FormattedMessage {...messages.projectColumn} />
					</Box>
				),
			},
			{
				key: 'actions',
				content: (
					<Box xcss={permissionHeaderStyles}>
						<FormattedMessage {...messages.permissionColumn} />
					</Box>
				),
			},
		],
	};

	const getRows = useCallback(
		() =>
			queryProjects?.projects?.map((project) => ({
				key: `row-${project.key}`,
				cells: [
					{
						key: `cell-${project.key}-1`,
						content: <ProjectContent name={project.name} projectId={project.id} />,
					},
					{
						key: `cell-${project.key}-2`,
						content: (
							<PermissionContent
								projectKey={project.key}
								canEdit={project.isValidEditProjectConfigAction}
								projectName={project.name}
							/>
						),
					},
				],
			})),
		[queryProjects],
	);

	return (
		<Stack xcss={containerStyles}>
			{!isJSMBoard && fg('simplified-boards-milestone-2_07hbk') ? (
				<FilterDetailsHeader>
					<FormattedMessage {...messages.header} />
				</FilterDetailsHeader>
			) : (
				<Heading as="h5" size="xxsmall">
					<Box as="span" xcss={headingInnerStyles}>
						<FormattedMessage {...messages.header} />
					</Box>
				</Heading>
			)}
			{isMaxSupportShowingProjectsReached ? (
				<>
					{formatMessage(messages.currentProjectsCountInBoard, {
						projectsCount: queryProjects.projectsCount,
					})}
				</>
			) : (
				<DynamicTable head={tableHead} rows={getRows()} width="fill" />
			)}
		</Stack>
	);
};

// clean up with simplified-boards-milestone-2_07hbk
const headingInnerStyles = xcss({
	color: 'color.text.subtle',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	font: token('font.heading.xxsmall'),
});

const containerStyles = xcss({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	rowGap: 'space.100',
});

const projectHeaderStyles = xcss({
	paddingInline: 'space.100',
});

const permissionHeaderStyles = xcss({
	paddingInline: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameContent = styled.div({
	paddingLeft: token('space.100'),
	paddingTop: token('space.025'),
	paddingBottom: token('space.025'),
	textAlign: 'start',
	display: 'flex',
	gap: token('space.100'),
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(> &)': {
		width: '528px',
		overflow: 'hidden',
	},
});

export { ProjectsIncluded };
