import React from 'react';
import pick from 'lodash/pick';
import memoizeOne from 'memoize-one';
import FolderIcon from '@atlaskit/icon/core/migration/folder-closed--folder';
import OfficeBuildingIcon from '@atlaskit/icon/core/migration/office-building';
import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';
import PersonIcon from '@atlaskit/icon/core/migration/person';
import WorldIcon from '@atlaskit/icon/core/migration/globe--world';
import {
	GROUP_SHARE,
	OPEN_SHARE,
	PROJECT_SHARE,
	PUBLIC_SHARE,
	USER_SHARE,
	type SharePermission,
	PROJECT_UNKNOWN_SHARE,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { PRIVATE_SHARE } from './constants.tsx';
import messages from './messages.tsx';
import type {
	SharePermissionOrPrivate,
	ShareType,
	ShareTypeProperties,
	Option,
	SharingPermissionType,
	SharingPermissionKey,
} from './types.tsx';

export const isPublicOrOpenOrPrivate = (type: ShareType): boolean =>
	// @ts-expect-error - TS2345 - Argument of type 'ShareType' is not assignable to parameter of type '"loggedin" | "global" | "private"'.
	[PUBLIC_SHARE, PRIVATE_SHARE, OPEN_SHARE].includes(type);

export const areSharePermissionsEqual = (
	share1: SharePermissionOrPrivate,
	share2: SharePermissionOrPrivate,
): boolean => {
	if (share1.type !== share2.type) return false;

	if (share1.type === PRIVATE_SHARE || share1.type === PUBLIC_SHARE || share1.type === OPEN_SHARE) {
		return true;
	}

	if (share1.type === GROUP_SHARE && share2.type === GROUP_SHARE) {
		if (share1.group.groupId && share2.group.groupId) {
			return share1.group.groupId === share2.group.groupId;
		}
		// second check is just for flow
		return share1.group.name === share2.group.name;
	}

	if (share1.type === USER_SHARE && share2.type === USER_SHARE) {
		// second check is just for flow
		return share1.user.accountId === share2.user.accountId;
	}

	if (share1.type === PROJECT_SHARE && share2.type === PROJECT_SHARE) {
		return (
			share1.project.id === share2.project.id &&
			(share1.role && share1.role.id) === (share2.role && share2.role.id)
		);
	}

	return false;
};

export const mapAccessTypeFromPermissions = memoizeOne(
	(sharePermissions: SharePermission[]): ShareType | undefined => {
		if (sharePermissions.length === 0) {
			return PRIVATE_SHARE;
		}

		return sharePermissions.length === 1
			? sharePermissions[0].type // PUBLIC_SHARE or OPEN_SHARE,
			: undefined;
	},
);

export const allShareTypesProperties: Record<ShareType, ShareTypeProperties> = {
	[PROJECT_SHARE]: {
		messageDescriptor: messages.project,
		icon: <FolderIcon spacing="spacious" label="" />,
	},
	[PROJECT_UNKNOWN_SHARE]: {
		messageDescriptor: messages.unknownProject,
		icon: <FolderIcon spacing="spacious" label="" />,
	},
	[GROUP_SHARE]: {
		messageDescriptor: messages.group,
		icon: <PeopleGroupIcon spacing="spacious" label="" />,
	},
	[OPEN_SHARE]: {
		messageDescriptor: messages.myOrganization,
		icon: <OfficeBuildingIcon spacing="spacious" label="" />,
	},
	[PUBLIC_SHARE]: {
		messageDescriptor: messages.public,
		icon: <WorldIcon spacing="spacious" label="" />,
	},
	[USER_SHARE]: {
		messageDescriptor: messages.user,
		icon: <PersonIcon spacing="spacious" label="" />,
	},
	[PRIVATE_SHARE]: {
		messageDescriptor: messages.private,
		icon: <PersonIcon spacing="spacious" label="" />,
	},
};

export const shareTypesProperties = pick(
	allShareTypesProperties,
	PROJECT_SHARE,
	GROUP_SHARE,
	OPEN_SHARE,
	PUBLIC_SHARE,
	USER_SHARE,
	PRIVATE_SHARE,
);

export const editTypesProperties = pick(
	allShareTypesProperties,
	PROJECT_SHARE,
	GROUP_SHARE,
	USER_SHARE,
	PRIVATE_SHARE,
);

export const shareeOptions: Option[] =
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(Object.keys(shareTypesProperties) as Array<keyof typeof shareTypesProperties>).map(
		(shareType) => ({
			...shareTypesProperties[shareType],
			isDisabled: false,
			value: shareType,
		}),
	);

export const editOptions: Option[] =
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(Object.keys(editTypesProperties) as Array<keyof typeof editTypesProperties>).map(
		(shareType) => ({
			...editTypesProperties[shareType],
			isDisabled: false,
			value: shareType,
		}),
	);

export const shareeOptionsWithPublicAccessDisabled: Option[] = shareeOptions.map((option) => ({
	...option,
	isDisabled: option.value === PUBLIC_SHARE,
}));

export const shareeOptionsWithoutPublicAccess: Option[] = shareeOptions.filter(
	(option) => option.value !== PUBLIC_SHARE,
);

export const sharingTypeToPermissionsMap: Record<SharingPermissionType, SharingPermissionKey> = {
	filter: 'globalsharing',
	dashboard: 'globalsharing',
};
