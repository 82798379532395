import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { BoardSettingsItemListSkeleton } from '@atlassian/jira-skeletons/src/ui/board-settings/BoardSettingsCardLayoutSkeleton.tsx';
import { ColorList } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/query-list/index.tsx';
import { CardColorStrategy } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/card-colors.tsx';
import { useDeleteCardColorHandler } from '../../../../controllers/handlers/use-delete-card-color-handler/index.tsx';
import { useEditCardColorHandler } from '../../../../controllers/handlers/use-edit-card-color-handler/index.tsx';
import { useResetToGlobalColor } from '../../../../controllers/handlers/use-reset-to-global-color-handler/index.tsx';
import {
	useCardColors,
	useCardColorStrategy,
} from '../../../../controllers/selectors/colors/index.tsx';
import { useCanEdit } from '../../../../controllers/selectors/permissions/index.tsx';
import { DeleteStaleCardColorModal } from './delete-stale-card-color-modal/index.tsx';
import { EmptyView } from './empty-view/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

type NonQueryStrategyProps = {
	headerName: string;
};

export const NonQueryStrategy = ({ headerName }: NonQueryStrategyProps) => {
	const { formatMessage } = useIntl();

	const [cardColors] = useCardColors();
	const [strategy] = useCardColorStrategy();
	const [canEdit] = useCanEdit();

	const { onInlineEditSubmit } = useEditCardColorHandler();
	const {
		beginItemDelete,
		abortItemDelete,
		commitItemDelete,
		isDeleteSubmitting,
		isDeleteModalOpen,
		toDeleteItemId,
	} = useDeleteCardColorHandler();

	const colorItems = useMemo(() => {
		const isAssigneeStrategy = strategy === CardColorStrategy.assignee;

		return cardColors.map((cardColor) => {
			const isUnassignedColor = isAssigneeStrategy && !cardColor.value && !cardColor.displayValue;
			return {
				id: String(cardColor.id),
				color: cardColor.color,
				name: isUnassignedColor
					? formatMessage(messages.unassignedDisplayValue)
					: cardColor.displayValue,
				query: '',
				isUsed: isUnassignedColor || cardColor.isUsed,
				isGlobalColor: cardColor.isGlobalColor,
			};
		});
	}, [cardColors, formatMessage, strategy]);

	const staleColorWarningMessage = useMemo(() => {
		if (strategy === CardColorStrategy.assignee) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.staleColorWarningMessageForAssigneeIssueTermRefresh
					: messages.staleColorWarningMessageForAssignee,
			);
		}

		if (strategy === CardColorStrategy.issueType) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.staleColorWarningMessageForIssueTypeIssueTermRefresh
					: messages.staleColorWarningMessageForIssueType,
			);
		}

		if (strategy === CardColorStrategy.requestType) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.staleColorWarningMessageForRequestTypeIssueTermRefresh
					: messages.staleColorWarningMessageForRequestType,
			);
		}

		if (fg('expose_delete_button_for_non_used_priorities')) {
			if (strategy === CardColorStrategy.priority) {
				return formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.staleColorWarningMessageForPriorityIssueTermRefresh
						: messages.staleColorWarningMessageForPriority,
				);
			}
		}
	}, [strategy, formatMessage]);

	const deletingItemName = useMemo(() => {
		if (!toDeleteItemId) {
			return null;
		}

		const deletingItem = colorItems.find(({ id }) => id === toDeleteItemId);

		if (deletingItem) {
			return deletingItem.name;
		}

		return null;
	}, [colorItems, toDeleteItemId]);

	const { resetToGlobalColor, isResettingToGlobalColor } = useResetToGlobalColor();

	if (isResettingToGlobalColor) {
		return <BoardSettingsItemListSkeleton />;
	}

	return (
		<CardColorNonQueryListContainer>
			<ColorList
				colorItems={colorItems}
				isReadOnly={!canEdit}
				isNonQueryColor
				headerName={headerName}
				staleColorWarningMessage={staleColorWarningMessage}
				// these handlers aren't nullable even though they're not required
				onEdit={noop}
				onRank={noop}
				onDelete={staleColorWarningMessage ? beginItemDelete : noop}
				onColorChange={onInlineEditSubmit}
				onResetToGlobal={strategy === CardColorStrategy.priority ? resetToGlobalColor : undefined}
				emptyView={<EmptyView />}
			/>

			{isDeleteModalOpen && deletingItemName && (
				<DeleteStaleCardColorModal
					deletingItemName={deletingItemName}
					colorStrategy={strategy}
					onCancel={abortItemDelete}
					onSubmit={commitItemDelete}
					isSubmitting={isDeleteSubmitting}
				/>
			)}
		</CardColorNonQueryListContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardColorNonQueryListContainer = styled.div({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRow: 'auto',
	gridTemplateColumns: '1fr',
	gap: token('space.250'),
});
