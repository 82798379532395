import React, { Component } from 'react';
import { JiraModal as AkModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { ModalDialog as SoftwareModalDialog } from '@atlassian/jira-software-modal-dialog/src/ui/modal-dialog/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { editOptions, shareeOptions } from './model/index.tsx';
import type { InternalProps, ExternalProps } from './model/types.tsx';
import SharingPermissionsDataProvider from './services/sharing-permissions/index.tsx';
import {
	getShareeOptions,
	isCreateSharedObjectsPermission,
	getSharePermissions,
} from './services/sharing-permissions/utils.tsx';
import View from './view/index.tsx';

const ModalDialog = componentWithFG(
	'jfp_a11y_fix_dashboard_actions_modal_accessibility',
	SoftwareModalDialog,
	AkModalDialog,
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { shareeOptions, editOptions } from './model';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ENTITY_NAME_FIELD_NAME,
	SHARE_PERMISSIONS_FIELD_NAME,
	ENTITY_DESCRIPTION_FIELD_NAME,
	EDIT_PERMISSIONS_FIELD_NAME,
} from './model/constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	OperationData,
	OperationResult,
	ServerValidationErrors,
	ShareType,
	Option,
	Options,
	ServerValidationErrorFieldKey,
} from './model/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { toServerValidationError } from './model/types';

// eslint-disable-next-line jira/react/no-class-components
class ShareableEntityDialog<OR> extends Component<InternalProps<OR>> {
	static defaultProps = {
		isNameReadOnly: false,
		isShareScopeLoading: false,
		initialOwner: undefined,
		shareeOptions,
		editOptions,
	};

	render() {
		return (
			<AppBase>
				<AppStyle>
					<View {...this.props} />
				</AppStyle>
			</AppBase>
		);
	}
}

export const SharingPermissionAwareShareableEntityDialog = <OR,>({
	avoidInitialPublicSharePermission = false,
	sharingPermissionType,
	sharePermissions = [],
	viewersCustomMessage = '',
	nameTooltip = '',
	allowSharePublicOrganizationOnly = false,
	editPermissions = [],
	forceHideEditors = false,
	isShareScopeLoading = false,
	isNameReadOnly = false,
	...props
}: ExternalProps<OR>) => (
	<SharingPermissionsDataProvider>
		{({ data, sharedObjectsPermission, loading }) => {
			const shareeOptionsFiltered = getShareeOptions(
				{ data, sharedObjectsPermission, loading },
				sharePermissions,
				sharingPermissionType,
				allowSharePublicOrganizationOnly,
			);
			const entitySharePermissions = getSharePermissions(
				{ data, sharedObjectsPermission, loading },
				sharePermissions,
				avoidInitialPublicSharePermission,
			);
			const canCreateSharedObjectsPermission =
				isCreateSharedObjectsPermission(sharedObjectsPermission);

			return (
				<ShareableEntityDialog
					{...props}
					isEditorListShown={
						!forceHideEditors &&
						(sharingPermissionType === 'dashboard' || sharingPermissionType === 'filter')
					}
					isNameReadOnly={isNameReadOnly}
					sharePermissions={entitySharePermissions}
					viewersCustomMessage={viewersCustomMessage}
					nameTooltip={nameTooltip}
					shareeOptions={shareeOptionsFiltered}
					editPermissions={
						canCreateSharedObjectsPermission === false || loading ? [] : editPermissions
					}
					editOptions={editOptions}
					isShareScopeLoading={isShareScopeLoading || loading}
				/>
			);
		}}
	</SharingPermissionsDataProvider>
);

export const SharingPermissionAwareShareableEntityModal = <OR,>(props: ExternalProps<OR>) => (
	<ShortcutScope>
		<ModalDialog
			messageId="shareable-entity-dialog.modal-dialog"
			messageType="transactional"
			width="medium"
			onClose={props.onCancel}
			shouldScrollInViewport
		>
			<SharingPermissionAwareShareableEntityDialog {...props} />
		</ModalDialog>
	</ShortcutScope>
);

export default ShareableEntityDialog;
