// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getCurrentEstimationStatistic = (state: State) => state.currentEstimationStatistic;

export const useCurrentEstimationStatistic = createHook(Store, {
	selector: getCurrentEstimationStatistic,
});

export const getCurrentTrackingStatistic = (state: State) => state.currentTrackingStatistic;

export const useCurrentTrackingStatistic = createHook(Store, {
	selector: getCurrentTrackingStatistic,
});

export const getAvailableEstimationStatistics = (state: State) =>
	state.availableEstimationStatistics;

export const useAvailableEstimationStatistics = createHook(Store, {
	selector: getAvailableEstimationStatistics,
});
