import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import InlineEdit from '@atlaskit/inline-edit';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';

// replace with react 18 useId once stable
const useId = () => useMemo(() => `uif-inline-edit-${Math.random()}-${Math.random()}`, []);

export const InlineTextEditWithBorder = ({
	defaultValue,
	onConfirm,
	onCancel,
	isDisabled,
	isRequired,
	label,
	editButtonLabel,
}: {
	defaultValue: string;
	onConfirm: (e: string) => void;
	onCancel?: () => void;
	isDisabled: boolean;
	isRequired: boolean;
	label: string;
	editButtonLabel: string;
}) => {
	const [editing, setEditting] = useState(false);
	const labelId = useId();
	const inputId = useId();
	const ref = useRef<HTMLDivElement>(null);
	const editViewInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (editing) {
			// using setTimeout 0 instead of raf since unit tests fail with raf
			// probably because editViewInputRef is not set yet
			setTimeout(() => {
				editViewInputRef.current?.focus();
			}, 0);
		}
	}, [editing]);

	useEffect(() => {
		const labelEl = ref.current?.querySelector('label');
		if (!labelEl) return;
		labelEl.htmlFor = inputId;
		labelEl.id = labelId;
	});

	const onEdit = useCallback(() => setEditting(true), []);
	const onCancelInner = useCallback(() => {
		setEditting(false);
		onCancel?.();
	}, [onCancel]);
	const onConfirmInner = useCallback(
		(update: string) => {
			onConfirm(update);
			setEditting(false);
		},
		[onConfirm],
	);

	return (
		<div ref={ref}>
			<InlineEdit
				readViewFitContainerWidth
				onConfirm={onConfirmInner}
				onEdit={onEdit}
				onCancel={onCancelInner}
				defaultValue={defaultValue}
				isEditing={editing && !isDisabled}
				isRequired={isRequired}
				editButtonLabel={editButtonLabel}
				label={label}
				readView={() => (
					<ReadViewInput
						inputId={inputId}
						labelId={labelId}
						isDisabled={isDisabled}
						isRequired={isRequired}
						defaultValue={defaultValue}
						onEdit={onEdit}
					/>
				)}
				editView={({ errorMessage, ...fieldProps }) => (
					<TextField
						{...fieldProps}
						aria-labelledby={labelId}
						id={inputId}
						testId="software-board-settings-rapidboard-config-common.ui.inline-edit-with-border.editmode"
						autoComplete="off"
						ref={editViewInputRef}
					/>
				)}
			/>
		</div>
	);
};

const noop = () => undefined;

export const ReadViewInput = ({
	inputId,
	labelId,
	defaultValue,
	isDisabled,
	isRequired,
	onEdit,
}: {
	inputId: string;
	labelId: string;
	defaultValue: string;
	isRequired?: boolean;
	isDisabled: boolean;
	onEdit: () => void;
}) => (
	<ReadViewRoot>
		<TextField
			// -1 because `inline-edit` renders a button which gains focus
			// before this and in hitting enter focus shifts
			tabIndex={-1}
			id={inputId}
			aria-labelledby={labelId}
			onFocus={onEdit}
			onChange={noop}
			isDisabled={isDisabled}
			isRequired={isRequired}
			value={defaultValue}
			autoComplete="off"
		/>
	</ReadViewRoot>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewRoot = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'button:focus + div > &:before': {
		position: 'absolute',
		boxSizing: 'border-box',
		content: ' ',
		display: 'block',
		width: '100%',
		height: '100%',
		borderWidth: `${token('space.025')}`,
		borderStyle: 'solid',
		borderColor: `${token('color.border.focused')}`,
		borderRadius: '3px',
		pointerEvents: 'none',
	},
	// inline-edit tends wrap the read only view in
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'div:has(> &), button:focus + div:has(> &)': {
		borderWidth: '0',
		display: 'block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'button:focus:has(+ div > &)': {
		outline: 'none',
	},
});
