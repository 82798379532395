/**
 * @generated SignedSource<<380a376aa6089a9186aafbbe5b2d4d22>>
 * @relayHash 5455d14b6feccf1c62876ffaee7c86d2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 941f930455fe672cd7c67c20280a9cd226cafd27761bbbce6a7aafe727435ceb

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraCalendarMode = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type JiraCalendarWeekStart = "MONDAY" | "SATURDAY" | "SUNDAY" | "%future added value";
export type JiraCalendarViewConfigurationInput = {
  date?: AGG$DateTime | null | undefined;
  endDateField?: string | null | undefined;
  mode?: JiraCalendarMode | null | undefined;
  startDateField?: string | null | undefined;
  viewId?: string | null | undefined;
  weekStart?: JiraCalendarWeekStart | null | undefined;
};
export type dateFieldsPageQuery$variables = {
  configuration?: JiraCalendarViewConfigurationInput | null | undefined;
  includeUserPermissions: boolean;
  projectARIs: ReadonlyArray<string>;
  scenario: string;
};
export type dateFieldsPageQuery$data = {
  readonly jira: {
    readonly jiraBoard?: {
      readonly canEdit?: boolean | null | undefined;
    };
    readonly jiraCalendar: {
      readonly endDateField: {
        readonly id: string;
        readonly name: string;
        readonly type: string;
      };
      readonly startDateField: {
        readonly id: string;
        readonly name: string;
        readonly type: string;
      };
    };
    readonly jiraProjects: ReadonlyArray<{
      readonly associatedIssueLayoutFields: {
        readonly " $fragmentSpreads": FragmentRefs<"fieldSection_softwareBoardSettingsDateFields">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
};
export type dateFieldsPageQuery = {
  response: dateFieldsPageQuery$data;
  variables: dateFieldsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configuration"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUserPermissions"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectARIs"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scenario"
},
v4 = [
  {
    "kind": "Variable",
    "name": "configuration",
    "variableName": "configuration"
  },
  {
    "fields": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "scenario"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "kind": "ObjectValue",
    "name": "scope"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "type"
},
v8 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "projectARIs"
  }
],
v9 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "includedFieldTypes": [
        "CUSTOM_DATETIME",
        "CUSTOM_DATE_PICKER",
        "STANDARD_DUE_DATE"
      ]
    }
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "scenario"
  }
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "canEdit"
    }
  ],
  "type": "JiraBoard"
},
v12 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v13 = [
  (v12/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "dateFieldsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v4/*: any*/),
                "concreteType": "JiraCalendar",
                "kind": "LinkedField",
                "name": "jiraCalendar",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "LinkedField",
                      "name": "startDateField",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraCalendar.startDateField.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v6/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraCalendar.startDateField.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraCalendar.startDateField.type"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.jiraCalendar.startDateField"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "LinkedField",
                      "name": "endDateField",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraCalendar.endDateField.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v6/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraCalendar.endDateField.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraCalendar.endDateField.type"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.jiraCalendar.endDateField"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraCalendar"
            },
            {
              "args": (v8/*: any*/),
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "jiraProjects",
              "plural": true,
              "selections": [
                {
                  "args": (v9/*: any*/),
                  "concreteType": "JiraFieldConnection",
                  "kind": "LinkedField",
                  "name": "associatedIssueLayoutFields",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "fieldSection_softwareBoardSettingsDateFields"
                    }
                  ],
                  "storageKey": "associatedIssueLayoutFields(input:{\"includedFieldTypes\":[\"CUSTOM_DATETIME\",\"CUSTOM_DATE_PICKER\",\"STANDARD_DUE_DATE\"]})"
                }
              ]
            },
            {
              "condition": "includeUserPermissions",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v10/*: any*/),
                    "kind": "LinkedField",
                    "name": "jiraBoard",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/)
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.jiraBoard"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "dateFieldsPageQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraCalendar",
            "kind": "LinkedField",
            "name": "jiraCalendar",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "startDateField",
                "plural": false,
                "selections": (v13/*: any*/)
              },
              {
                "kind": "LinkedField",
                "name": "endDateField",
                "plural": false,
                "selections": (v13/*: any*/)
              }
            ]
          },
          {
            "args": (v8/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjects",
            "plural": true,
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraFieldConnection",
                "kind": "LinkedField",
                "name": "associatedIssueLayoutFields",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraField",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "fieldId"
                          },
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "typeKey"
                          },
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "associatedIssueLayoutFields(input:{\"includedFieldTypes\":[\"CUSTOM_DATETIME\",\"CUSTOM_DATE_PICKER\",\"STANDARD_DUE_DATE\"]})"
              },
              (v5/*: any*/)
            ]
          },
          {
            "condition": "includeUserPermissions",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v10/*: any*/),
                "kind": "LinkedField",
                "name": "jiraBoard",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "941f930455fe672cd7c67c20280a9cd226cafd27761bbbce6a7aafe727435ceb",
    "metadata": {},
    "name": "dateFieldsPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "29957b8f38033b61f4771a83bc987dfb";

export default node;
