import React from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { PROJECT_UNKNOWN_SHARE } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { allShareTypesProperties } from '../../../../../model/index.tsx';
import { IconWrapper, NameWrapper } from '../common/styled.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const WarningIconWrapper = styled.span({
	display: 'inline-block',
	paddingLeft: token('space.050'),
	verticalAlign: 'middle',
});

const UnknownProject = () => {
	const { formatMessage } = useIntl();
	const shareTypeProperties = allShareTypesProperties[PROJECT_UNKNOWN_SHARE];

	return (
		<>
			<IconWrapper>{shareTypeProperties.icon}</IconWrapper>
			<NameWrapper>
				{formatMessage(shareTypeProperties.messageDescriptor)}
				<Tooltip
					content={formatMessage(messages.unknownProjectWarning)}
					/* @ts-expect-error Incompatible ref type in tooltip */
					tag={WarningIconWrapper}
				>
					<WarningIcon
						label={formatMessage(messages.unknownProjectWarning)}
						LEGACY_size="medium"
						spacing="spacious"
						color={token('color.icon.accent.gray')}
					/>
				</Tooltip>
			</NameWrapper>
		</>
	);
};

export default UnknownProject;
