import React, { useCallback, useMemo, useState } from 'react';
import isSubstringFound from '@atlassian/jira-common-util-is-substring-found/src/index.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { ErrorAutoDismissFlag } from '@atlassian/jira-flags/src/common/ui/components';
import { useIntl } from '@atlassian/jira-intl';
import {
	useUpdateBoardLocation as useUpdateBoardLocationState,
	useRemoveEditAccess,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/main.tsx';
import { useBoardLocation } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useIsCMPBoard,
	useRapidViewId,
	useWarnBeforeEditingLocation,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useUserConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/user/index.tsx';
import type { BoardLocationModel } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import {
	type BoardLocations,
	useFetchBoardLocations,
	type Location,
} from '../../services/fetch-board-location/index.tsx';
import { useFetchMyPermissions } from '../../services/fetch-my-permissions/index.tsx';
import { useUpdateRemoteBoardLocation } from '../../services/update-remote-board-location/index.tsx';
import messages from './messages.tsx';
import { LocationType, type GroupOption } from './types.tsx';

const getUserLocation = (user: Location) => ({ ...user, key: 'user' });

export const useBoardLocations = () => {
	const { formatMessage } = useIntl();
	const [boardLocation] = useBoardLocation();
	const [boardLocations, setBoardLocations] = useState<BoardLocations>();
	const { commit: fetchBoardLocations } = useFetchBoardLocations();
	const fetchBoardLocationsFromCache = useCallback(
		async (query: string) => {
			if (boardLocations) {
				return boardLocations;
			}
			const locations = await fetchBoardLocations(query);
			setBoardLocations(locations);
			return locations;
		},
		[boardLocations, fetchBoardLocations],
	);

	const applyFilter = useCallback(
		(query: string, locations?: BoardLocations) => {
			const projectLocations = locations
				? locations.projects
						.filter((projectLocation) => isSubstringFound(projectLocation.displayName, query))
						.map((projectLocation) => ({
							...projectLocation,
							key: LocationType.PROJECT,
						}))
				: [];
			const userLocation =
				locations && locations.user && isSubstringFound(locations.user.displayName, query)
					? [getUserLocation(locations.user)]
					: [];
			return [
				{
					label: formatMessage(messages.projectsSelectGroup),
					options: projectLocations,
				},
				{
					label: formatMessage(messages.userSelectGroup),
					options: userLocation,
				},
			];
		},
		[formatMessage],
	);

	const fetchLocations = useCallback(
		async (query: string): Promise<GroupOption[]> => {
			const locations = await fetchBoardLocationsFromCache(query);
			return applyFilter(query, locations);
		},
		[applyFilter, fetchBoardLocationsFromCache],
	);

	return { boardLocation, fetchBoardLocations: fetchLocations };
};

const useHasAccessAtLocation = () => {
	const { commit: fetchMyPermissions } = useFetchMyPermissions();
	const { name } = useUserConfig();
	const warnBeforeEditingLocation = useWarnBeforeEditingLocation();

	const hasAccessAtLocation = useCallback(
		async (newLocation: Location): Promise<boolean | null> => {
			if (!warnBeforeEditingLocation) return true;

			if (newLocation.key === LocationType.PROJECT) {
				const projectId = newLocation.id;
				if (projectId == null) return null;

				const response = await fetchMyPermissions({ projectId });
				const havePermission = response?.permissions.ADMINISTER_PROJECTS.havePermission;

				if (havePermission == null) return null;
				return havePermission;
			}

			return name === newLocation.name;
		},
		[name, warnBeforeEditingLocation, fetchMyPermissions],
	);

	return { hasAccessAtLocation };
};

export const boardLocationToOption = (boardLocation: BoardLocationModel): Location | null => {
	if (boardLocation == null) return null;

	let id: string | undefined;
	let key: string;
	if (boardLocation.projectId != null) {
		id = boardLocation.projectId;
		key = LocationType.PROJECT;
	} else {
		id = boardLocation.userLocationId;
		key = LocationType.USER;
	}

	return {
		displayName: boardLocation.name,
		name: boardLocation.name,
		id,
		avatar: boardLocation.avatarURI,
		key,
	};
};

export const useUpdateBoardLocation = () => {
	const { commit: updateRemoteBoardLocation } = useUpdateRemoteBoardLocation();
	const updateBoardLocation = useUpdateBoardLocationState();
	const rapidViewId = useRapidViewId();
	const isCMPBoard = useIsCMPBoard();
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const [boardLocation] = useBoardLocation();

	const initialSelectedOption = useMemo<Location | null>(
		() => boardLocationToOption(boardLocation),
		[boardLocation],
	);

	const [selected, setSelected] = useState(initialSelectedOption);
	const [showModal, setShowModal] = useState<boolean>(false);

	const removeEditAccess = useRemoveEditAccess();
	const { hasAccessAtLocation } = useHasAccessAtLocation();

	const onUpdate = async (option: Location, confirmation: boolean) => {
		setSelected(option);

		// When unknown just proceed
		const retainsAccess = (await hasAccessAtLocation(option)) ?? true;
		if (!retainsAccess && !confirmation) {
			setShowModal(true);
			return;
		}

		const result = await updateRemoteBoardLocation({
			locationId: option.key === LocationType.PROJECT ? option.id : undefined,
			locationType: option.key || '',
			rapidViewId,
		});

		setShowModal(false);

		if (result == null || result.errors) {
			setSelected(initialSelectedOption);
			showFlag((props) => (
				<ErrorAutoDismissFlag
					title={formatMessage(messages.updateBoardLocationFailureTitle)}
					description={formatMessage(messages.updateBoardLocationFailureDescription)}
					messageId="software-board-settings-general-settings.ui.board-location-field.error-auto-dismiss-flag"
					messageType="transactional"
					{...props}
				/>
			));

			fireTrackEvent('updateBoardLocation failed');
			return;
		}

		updateBoardLocation({
			avatarURI: option.avatar,
			name: option.displayName,
		});

		if (!retainsAccess) {
			removeEditAccess();
		}

		showFlag((props) => (
			<SuccessFlagWithBoardLink
				isCMPBoard={isCMPBoard}
				title={formatMessage(messages.updateBoardLocationSuccessfulTitle)}
				messageId="software-board-settings-general-settings.ui.board-location-field.success-flag-with-board-link"
				messageType="transactional"
				{...props}
			/>
		));
		fireTrackEvent('updateBoardLocation succeeded');
	};

	const onModalSubmit = async () => {
		if (selected != null) {
			await onUpdate(selected, true);
			setShowModal(false);
		}
	};

	return {
		boardLocation: selected,
		showModal,
		updateBoardLocation: (option: Location) => onUpdate(option, false),
		onModalSubmit,
		onModalDismiss: () => {
			setShowModal(false);
			setSelected(initialSelectedOption);
		},
	};
};
