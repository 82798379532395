import type { BoardAdmins } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/board-admin.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateBoardAdmins =
	(boardAdmins: BoardAdmins) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			boardAdmins,
		});
	};

export type UpdateBoardAdminAction = typeof updateBoardAdmins;
