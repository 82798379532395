// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type StoreActionApi } from '@atlassian/react-sweet-state';

type State = {
	boardName: string | null;
};

const INITIAL_STATE: State = {
	boardName: null,
};

const actions = {
	updateBoardName:
		(name: string) =>
		({ setState, getState }: StoreActionApi<State>) => {
			setState({
				...getState(),
				boardName: name,
			});
		},
};

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	initialState: INITIAL_STATE,
	actions,
	name: 'SettingsContainerState',
});

const useStore = createHook(Store);

export const useSettingsContainerContext = () => {
	const [state, allActions] = useStore();
	return {
		...state,
		...allActions,
	};
};
