// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getLevelOneName = (state: State) => state.levelOneName;

export const useLevelOneName = createHook(Store, {
	selector: getLevelOneName,
});
