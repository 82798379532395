import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import type {
	Swimlane,
	SwimlaneStrategy,
} from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/swimlanes.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';
import { Store } from '../../main.tsx';

export const getSwimlanesWithoutDefault = (state: State): Swimlane[] => state.swimlanes;

export const useSwimlanesWithoutDefault = createHook(Store, {
	selector: getSwimlanesWithoutDefault,
});

export const getIsOptimisticSwimlanes = (state: State): boolean => state.isOptimisticSwimlanes;

export const useIsOptimisticSwimlanes = createHook(Store, {
	selector: getIsOptimisticSwimlanes,
});

export const getDefaultSwimlane = (state: State): Swimlane | null => state.defaultSwimlane;

export const useDefaultSwimlane = createHook(Store, {
	selector: getDefaultSwimlane,
});

export const getSwimlaneStrategy = (state: State): SwimlaneStrategy => state.swimlaneStrategy;

export const useSwimlaneStrategy = createHook(Store, {
	selector: getSwimlaneStrategy,
});

export const getItemToEdit = (state: State): QueryItem | null => state.itemToEdit;

export const useItemToEdit = createHook(Store, {
	selector: getItemToEdit,
});

export const getItemToDelete = (state: State): QueryItem | null => state.itemToDelete;

export const useItemToDelete = createHook(Store, {
	selector: getItemToDelete,
});
