import React, { type PropsWithChildren } from 'react';
import Heading from '@atlaskit/heading';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type FilterDetailsHeaderProps = {
	testId?: string;
};

export const FilterDetailsHeader = ({
	children,
	testId,
}: PropsWithChildren<FilterDetailsHeaderProps>) => (
	<Heading as="h5" size="xxsmall">
		<Inline as="span" testId={testId} xcss={headerStyles}>
			{children}
		</Inline>
	</Heading>
);

const headerStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
	marginTop: 'space.300',
});
