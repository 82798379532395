import React, { useEffect, useState } from 'react';
import { useFlagService } from '@atlassian/jira-flags';
import { ErrorAutoDismissFlag } from '@atlassian/jira-flags/src/common/ui/components';
import { useIntl } from '@atlassian/jira-intl';
import { useBoardFilterConfig } from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board-fields/index.tsx';
import {
	useRapidViewId,
	useIsCMPBoard,
} from '@atlassian/jira-software-board-settings-general-common/src/controllers/selectors/board/index.tsx';
import { useFireAnalyticsTrackEvent } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-fire-analytics-event/index.tsx';
import { SuccessFlagWithBoardLink } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/ui/success-flag-with-board-link/index.tsx';
import { useUpdateRemoteIssueRank } from '../../../services/update-remote-issue-rank/index.tsx';
import messages from './messages.tsx';

export const useBoardIssueRanking = () => {
	// This canEdit is different than useCanEdit and is for board filter
	const [{ id, isOrderedByRank, canEdit }, { updateSelectedBoardFilter }] = useBoardFilterConfig();
	const [issueRank, setIssueRank] = useState<boolean>(isOrderedByRank);

	useEffect(() => {
		setIssueRank(isOrderedByRank);
	}, [isOrderedByRank]);

	const isCMPBoard = useIsCMPBoard();
	const rapidViewId = useRapidViewId();

	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const { commit: updateIssueRank } = useUpdateRemoteIssueRank();
	const { fireTrackEvent } = useFireAnalyticsTrackEvent();

	const onUpdate = async () => {
		setIssueRank(true);

		const result = await updateIssueRank({
			id: rapidViewId,
		});

		setIssueRank(result !== undefined);

		if (result && !result.errors) {
			showFlag((props) => (
				<SuccessFlagWithBoardLink
					isCMPBoard={isCMPBoard}
					title={formatMessage(messages.updateBoardIssueRankSuccessfulTitle)}
					messageId="software-board-settings-general-filter-settings.ui.board-filter-details.issue-ranking.success-flag-with-board-link"
					messageType="transactional"
					{...props}
				/>
			));

			updateSelectedBoardFilter(result);

			fireTrackEvent('updateBoardIssueRank succeeded');
		} else {
			showFlag((props) => (
				<ErrorAutoDismissFlag
					title={formatMessage(messages.updateBoardIssueRankFailureTitle)}
					description={formatMessage(messages.updateBoardIssueRankFailureDescription)}
					messageId="software-board-settings-general-filter-settings.ui.board-filter-details.issue-ranking.error-auto-dismiss-flag"
					messageType="transactional"
					{...props}
				/>
			));
			fireTrackEvent('updateBoardIssueRank failed');
		}
	};

	return {
		filterQueryId: id,
		issueRank,
		canEdit,
		onUpdate,
	};
};
