import React from 'react';
import {
	SuccessAutoDismissFlag,
	type AsyncAutoDismissFlagProps,
} from '@atlassian/jira-flags/src/common/ui/components';
import { useIntl } from '@atlassian/jira-intl';
import { useRouter } from '@atlassian/react-resource-router';
import { useBoardUrl } from '../../controllers/use-board-url/index.tsx';
import messages from './messages.tsx';

type Props = AsyncAutoDismissFlagProps & {
	onDismissed?: (id: number | string) => void;
	isCMPBoard: boolean;
};

export const SuccessFlagWithBoardLink = (props: Props) => {
	const { formatMessage } = useIntl();
	const [, { push }] = useRouter();
	const { id, onDismissed, isCMPBoard } = props;
	const boardUrl = useBoardUrl(isCMPBoard);

	const actions =
		boardUrl !== undefined
			? [
					{
						content: formatMessage(messages.actionBoard),
						onClick: () => {
							push(boardUrl);
							if (onDismissed) onDismissed(id);
						},
					},
				]
			: [];

	return (
		<SuccessAutoDismissFlag
			actions={actions}
			{...props}
			messageId={
				props.messageId ??
				`software.board-settings.rapidboard-config-common.ui.success-flag-with-board-link.${id}`
			}
			messageType={props.messageType ?? 'transactional'}
		/>
	);
};
