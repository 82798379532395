import React from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

type Props = {
	title: string;
	description: React.ReactNode;
	actions?: React.ReactNode;
};

export const PageHeader = ({ title, actions, description }: Props) => (
	<PageHeaderWrapper>
		<PageTitle>
			<Heading as="h2" size="medium">
				{title}
			</Heading>
			{actions && <Actions>{actions}</Actions>}
		</PageTitle>
		<Description>{description}</Description>
	</PageHeaderWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageTitle = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Actions = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	color: `${token('color.text')}`,
	marginTop: token('space.150'),
	fontWeight: token('font.weight.regular'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageHeaderWrapper = styled.div({
	display: 'block',
});
