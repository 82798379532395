import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	childLevelIssuesToggleTitle: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.child-level-issues-toggle-title',
		defaultMessage: 'Include child-level issues',
		description: 'Title for the child-level issues toggle',
	},
	childLevelIssuesToggleLabel: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.child-level-issues-toggle-label',
		defaultMessage: 'Include child-level issues',
		description: 'Label for the child-level issues toggle',
	},
	childLevelIssuesToggleLabelIssueTermRefresh: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.child-level-issues-toggle-label-issue-term-refresh',
		defaultMessage: 'Include child-level work items',
		description: 'Label for the child-level issues toggle',
	},
	descriptionWithSprintInfo: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.description-with-sprint-info',
		defaultMessage:
			"Manage all types of issues on your timeline, like stories, tasks, and bugs. Timeline bars can be based on an issue's start and due date, or sprint dates.",
		description:
			'Description for the child-level issues toggle. Includes additional information how sprints affect the timeline.',
	},
	description: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.description',
		defaultMessage: 'Manage all types of issues on your timeline, like stories, tasks, and bugs.',
		description: 'Description for the child-level issues toggle.',
	},
	scheduleOptionsDescription: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.schedule-options-description',
		defaultMessage: 'This impacts rolled-up dates.',
		description: 'Description for the schedule options',
	},
	startAndDueDateScheduling: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.start-and-due-date-scheduling',
		defaultMessage: 'Schedule by start and due dates',
		description: 'Radio option text to select scheduling timeline bars by start and due dates',
	},
	sprintDatesScheduling: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.sprint-dates-scheduling',
		defaultMessage: 'Schedule by sprint dates',
		description: 'Radio option text to select scheduling timeline bars by sprint dates',
	},
	childLevelIssuesToggleTitleIssueTermRefresh: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.child-level-issues-toggle-title-issue-term-refresh',
		defaultMessage: 'Include child-level work items',
		description: 'Title for the child-level issues toggle',
	},
	descriptionWithSprintInfoIssueTermRefresh: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.description-with-sprint-info-issue-term-refresh',
		defaultMessage:
			"Manage all types of work items on your timeline, like stories, tasks, and bugs. Timeline bars can be based on a work item's start and due date, or sprint dates.",
		description:
			'Description for the child-level issues toggle. Includes additional information how sprints affect the timeline.',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-settings-timeline.timeline-settings-page.enable-timeline.include-child-level-issues.description-issue-term-refresh',
		defaultMessage:
			'Manage all types of work items on your timeline, like stories, tasks, and bugs.',
		description: 'Description for the child-level issues toggle.',
	},
});
