import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { InProductHelpButton } from '@atlassian/jira-insights-common/src/common/ui/in-product-help-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsJSMBoard } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-is-jsm-board/index.tsx';
import {
	LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SERVICEDESK,
	LEARN_MORE_ABOUT_FILTER_ARTICLE_SAC_ARTICLE_JIRA_SERVICEDESK,
	LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SERVICEDESK_ANALYTICS_ID,
	LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SOFTWARE,
	LEARN_MORE_ABOUT_FILTER_ARTICLE_SAC_ARTICLE_JIRA_SOFTWARE,
	LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SOFTWARE_ANALYTICS_ID,
	LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE,
	LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE_ANALYTICS_ID,
	LEARN_MORE_ABOUT_CALENDAR_SAC_ARTICLE,
} from '../../common/constants/in-product-help.tsx';
import { BoardFilterField } from './board-filter-field/index.tsx';
import messages from './messages.tsx';

export const BoardFilterSection = () => {
	const { formatMessage } = useIntl();
	const isJSMBoard = useIsJSMBoard();

	const articleId = isJSMBoard
		? LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SERVICEDESK
		: LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SOFTWARE;
	const articleAnalyticsId = isJSMBoard
		? LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SERVICEDESK_ANALYTICS_ID
		: LEARN_MORE_ABOUT_FILTER_ARTICLE_IPH_ARTICLE_JIRA_SOFTWARE_ANALYTICS_ID;
	const articleFallbackUrl = isJSMBoard
		? LEARN_MORE_ABOUT_FILTER_ARTICLE_SAC_ARTICLE_JIRA_SERVICEDESK
		: LEARN_MORE_ABOUT_FILTER_ARTICLE_SAC_ARTICLE_JIRA_SOFTWARE;

	const title = isJSMBoard ? messages.sectionTitle : messages.title;

	const onHelpClick = useCallback(
		(_e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, articleAnalyticsId),
		[articleAnalyticsId],
	);

	const onCalendarHelpClick = useCallback(
		(_e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE_ANALYTICS_ID),
		[],
	);

	const getJsmCalendarHelpLink = useCallback(
		(chunks: React.ReactNode[]) => {
			return fg('jsw-board-settings-general-page-a11y-fixes') ? (
				<HelpPanelLink
					articleId={LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE}
					appearance="link"
					spacing="none"
					onClick={onCalendarHelpClick}
					fallbackComponent={
						<Link
							href={LEARN_MORE_ABOUT_CALENDAR_SAC_ARTICLE}
							target="_blank"
							onClick={onCalendarHelpClick}
						>
							{chunks}
						</Link>
					}
				>
					{chunks}
				</HelpPanelLink>
			) : (
				<InProductHelpButton
					articleId={LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE}
					articleAnalyticsId={LEARN_MORE_ABOUT_CALENDAR_IPH_ARTICLE_ANALYTICS_ID}
					appearance="link"
				>
					{chunks}
				</InProductHelpButton>
			);
		},
		[onCalendarHelpClick],
	);

	return (
		<Stack
			space="space.150"
			testId="software-board-settings-general-filter-settings.ui.board-filter-section.container"
		>
			<Heading size="small" as="h3">
				{formatMessage(title)}
			</Heading>
			{isJSMBoard && (
				<SectionMessage>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.infoMessageIssueTermRefresh
							: messages.infoMessage,
						{
							button: getJsmCalendarHelpLink,
						},
					)}
				</SectionMessage>
			)}
			{fg('jsw-board-settings-general-page-a11y-fixes') ? (
				<Box xcss={aboutStyles}>
					<Text as="p">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.aboutIssueTermRefresh
								: messages.about,
							{
								button: (chunks: React.ReactNode[]) => (
									<HelpPanelLink
										articleId={articleId}
										appearance="link"
										spacing="none"
										onClick={onHelpClick}
										fallbackComponent={
											<Link href={articleFallbackUrl} target="_blank" onClick={onHelpClick}>
												{chunks}
											</Link>
										}
									>
										{chunks}
									</HelpPanelLink>
								),
							},
						)}
					</Text>
				</Box>
			) : (
				<About>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.aboutIssueTermRefresh
							: messages.about,
						{
							button: (chunks: React.ReactNode[]) => (
								<InProductHelpButton
									articleId={articleId}
									articleAnalyticsId={articleAnalyticsId}
									appearance="link"
								>
									{chunks}
								</InProductHelpButton>
							),
						},
					)}
				</About>
			)}
			<BoardFilterField />
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const About = styled.p({
	maxWidth: '660px',
	marginTop: '0',
});

const aboutStyles = xcss({
	maxWidth: '660px',
});
