/** @jsx jsx */
import React, { Component, type SyntheticEvent } from 'react';
import { styled, keyframes, css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { token } from '@atlaskit/tokens';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	GROUP_SHARE,
	OPEN_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	PUBLIC_SHARE,
	USER_SHARE,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PRIVATE_SHARE } from '../../../../model/constants.tsx';
import { allShareTypesProperties } from '../../../../model/index.tsx';
import type { SharePermissionOrPrivate } from '../../../../model/types.tsx';
import { IconWrapper, NameWrapper } from './common/styled.tsx';
import GroupName from './group-name/view.tsx';
import messages from './messages.tsx';
import projectNameMessages from './project-name/messages.tsx';
import ProjectName from './project-name/view.tsx';
import UnknownProject from './unknown-project/index.tsx';
import UserName from './user-name/view.tsx';

const assertUnreachable = (_x: never): never => {
	throw new Error("Didn't expect to get here");
};

const RemoveIcon = ({
	sharePermission,
	isEditorRow,
	intl,
}: {
	sharePermission: SharePermissionOrPrivate;
	isEditorRow: boolean;
} & Intl) => {
	const { type } = sharePermission;
	let sharePermissionMessage: string | undefined;
	switch (type) {
		case GROUP_SHARE:
			sharePermissionMessage = intl.formatMessage(messages.groupPermission, {
				groupName: sharePermission.group.name,
			});
			break;
		case PROJECT_SHARE:
			sharePermissionMessage = intl.formatMessage(messages.projectPermission, {
				projectName: sharePermission.project.name,
				projectRole: sharePermission.role?.name ?? intl.formatMessage(projectNameMessages.allRoles),
			});
			break;
		case USER_SHARE:
			sharePermissionMessage = intl.formatMessage(messages.userPermission, {
				displayName: sharePermission.user.displayName,
			});
			break;
		case OPEN_SHARE:
		case PUBLIC_SHARE:
		case PRIVATE_SHARE:
		case PROJECT_UNKNOWN_SHARE:
			sharePermissionMessage = intl.formatMessage(
				allShareTypesProperties[sharePermission.type].messageDescriptor,
			);
			break;
		default:
			assertUnreachable(type);
	}

	return (
		<CrossIcon
			spacing="spacious"
			label={intl.formatMessage(
				isEditorRow
					? messages.removeEditorSharePermissionButton
					: messages.removeViewerSharePermissionButton,
				{ sharePermission: sharePermissionMessage },
			)}
		/>
	);
};

type Props = {
	isDuplicated: boolean;
} & Intl & {
		index: number;
		sharePermission: SharePermissionOrPrivate;
		onRemove: (arg1: number, arg2: UIAnalyticsEvent) => void;
		forceHideRemoveButton: boolean | undefined;
		isEditorRow: boolean;
	};

// eslint-disable-next-line jira/react/no-class-components
export default class ShareeListRow extends Component<Props> {
	static defaultProps = {
		forceHideRemoveButton: false,
	};

	onRemove = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { index, onRemove } = this.props;

		onRemove(index, analyticsEvent);
	};

	renderSharePermission() {
		const { sharePermission, intl } = this.props;
		const { type } = sharePermission;

		switch (type) {
			case GROUP_SHARE:
				return <GroupName sharePermission={sharePermission} />;
			case PROJECT_SHARE:
				return <ProjectName sharePermission={sharePermission} />;
			case PROJECT_UNKNOWN_SHARE:
				return <UnknownProject />;
			case USER_SHARE:
				return <UserName sharePermission={sharePermission} />;
			case OPEN_SHARE:
			case PUBLIC_SHARE:
			case PRIVATE_SHARE: {
				const shareTypeProperties = allShareTypesProperties[sharePermission.type];
				return (
					<>
						<IconWrapper>{shareTypeProperties.icon}</IconWrapper>
						<NameWrapper>{intl.formatMessage(shareTypeProperties.messageDescriptor)}</NameWrapper>
					</>
				);
			}
			default:
				assertUnreachable(type);
				return null;
		}
	}

	renderRow() {
		const shouldShowRemoveButton =
			!this.props.forceHideRemoveButton && this.props.sharePermission.type !== PRIVATE_SHARE;

		const content = (
			<>
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
					<div css={sharePermissionStyles}>{this.renderSharePermission()}</div>
				) : (
					<NameContainer>{this.renderSharePermission()}</NameContainer>
				)}
				{shouldShowRemoveButton && (
					<RemoveButtonWrapper>
						<Button
							appearance="subtle"
							iconBefore={
								<RemoveIcon
									intl={this.props.intl}
									isEditorRow={this.props.isEditorRow}
									sharePermission={this.props.sharePermission}
								/>
							}
							onClick={this.onRemove}
						/>
					</RemoveButtonWrapper>
				)}
			</>
		);

		if (isVisualRefreshEnabled() && fg('visual-refresh_drop_3')) {
			return (
				<div
					data-testid="shareable-entity-dialog.access.sharee-list.sharee-list-row.group-permission"
					css={groupPermissionStyles}
					role="listitem"
				>
					{content}
				</div>
			);
		}

		return (
			<RowContainer
				data-testid="shareable-entity-dialog.access.sharee-list.sharee-list-row.group-permission"
				role="listitem"
			>
				{content}
			</RowContainer>
		);
	}

	render() {
		const { isDuplicated } = this.props;

		const row = this.renderRow();

		return isDuplicated ? <BlinkWrapper>{row}</BlinkWrapper> : row;
	}
}

const groupPermissionStyles = css({
	display: 'flex',
	alignItems: 'center',
	// Min height is needed to account for rows without the remove button
	minHeight: token('space.400'),
});

const sharePermissionStyles = css({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	minWidth: 0,
	gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div({
	maxWidth: '100%',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoveButtonWrapper = styled.div({
	flex: '0 0 auto',
});

const blinkKeyFrames = keyframes({
	'0%': {
		background: 'none',
		boxShadow: 'none',
	},
	'25%': {
		backgroundColor: token('color.background.selected'),
		boxShadow: `0 0 0 1px ${token('color.background.danger')}`,
	},
	'75%': {
		backgroundColor: token('color.background.selected'),
		boxShadow: `0 0 0 1px ${token('color.background.danger')}`,
	},
	'100%': {
		background: 'none',
		boxShadow: 'none',
	},
});

// position: is specified for z-index to work. z-index is needed to show box-shadow on top of sibling elements

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BlinkWrapper = styled.div({
	position: 'relative',
	zIndex: 1000,
	borderRadius: '2px',
	animation: `${blinkKeyFrames} 2s linear 0s 1`,
});
