import type { BoardLocationModel } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/types/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../../common/types/index.tsx';

export const updateBoardLocation =
	(boardLocationModel: BoardLocationModel) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const currentState = getState();
		setState({
			...currentState,
			boardLocationModel,
		});
	};

export type UpdateBoardLocationAction = typeof updateBoardLocation;
