import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	infoMessage: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.info-message',
		defaultMessage:
			'Board and calendar use the same filter. Making updates here will also affect the issues your team can see on the calendar. <button>Find out more about calendar</button>',
		description:
			'Info the user that the board filter also shared with calendar. The <button> will be replace with a In product help link',
	},
	about: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.about',
		defaultMessage:
			'The board filter determines the issues that appear on your board. It’s based on Jira Query Language (JQL) and can include one or more projects. <button>Read more about filters</button>.',
		description: 'Describes the purpose of the board filter.',
	},
	aboutIssueTermRefresh: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.about-issue-term-refresh',
		defaultMessage:
			'The board filter determines the work items that appear on your board. It’s based on Jira Query Language (JQL) and can include one or more projects. <button>Read more about filters</button>.',
		description: 'Describes the purpose of the board filter.',
	},
	title: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.title',
		defaultMessage: 'Board filter',
		description: 'Title of the board filter section of the page.',
	},
	sectionTitle: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.section-title',
		defaultMessage: 'Filter',
		description: 'Title of the board and calendar filter section of the page.',
	},
	infoMessageIssueTermRefresh: {
		id: 'software-board-settings-general-filter-settings.board-filter-section.info-message-issue-term-refresh',
		defaultMessage:
			'Board and calendar use the same filter. Making updates here will also affect the work items your team can see on the calendar. <button>Find out more about calendar</button>',
		description:
			'Info the user that the board filter also shared with calendar. The <button> will be replace with a In product help link',
	},
});
