import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../common/constants/data-test.tsx';
import { DragHandleButton } from './drag-handle-button/index.tsx';
import messages from './messages.tsx';

export type RankEffect = 'MoveUp' | 'MoveDown';

export const DragHandle = ({
	index,
	onRank,
	isTop,
	isBottom,
	name,
}: {
	index: number;
	onRank: (index: number, effect: RankEffect) => void;
	isTop: boolean;
	isBottom: boolean;
	name: string;
}) => {
	const { formatMessage } = useIntl();

	const onMoveUp = useCallback(() => {
		onRank(index, 'MoveUp');
	}, [onRank, index]);

	const onMoveDown = useCallback(() => {
		onRank(index, 'MoveDown');
	}, [onRank, index]);

	return (
		<DragHandleContainer>
			<DropdownMenu
				spacing="cozy"
				shouldRenderToParent
				placement="bottom-start"
				trigger={(triggerProps) => <DragHandleButton name={name} {...triggerProps} />}
			>
				<DropdownItemGroup testId={dataTestIds.queryListDragHandleMenu}>
					<DropdownItem
						isDisabled={isTop}
						onClick={onMoveUp}
						testId="software-board-settings-sortable-table.ui.sortable-table.drag-handle.move-up"
					>
						{formatMessage(messages.moveUp)}
					</DropdownItem>
					<DropdownItem
						isDisabled={isBottom}
						onClick={onMoveDown}
						testId="software-board-settings-sortable-table.ui.sortable-table.drag-handle.move-down"
					>
						{formatMessage(messages.moveDown)}
					</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
		</DragHandleContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandleContainer = styled.div({
	display: 'flex',
	marginLeft: token('space.100'),
	justifyContent: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'td:has(> &)': {
		width: token('space.500'),
		borderBottom: `${token('color.border')} 1px solid`,
	},
});
