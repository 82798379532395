import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { QueryItem } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/types/index.tsx';
import { useExternalRowFocusRecovery } from '@atlassian/jira-software-board-settings-query-list-config-common/src/common/ui/focus-handler/index.tsx';
import { QueryList } from '@atlassian/jira-software-board-settings-query-list-config-common/src/ui/query-list/index.tsx';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import { useCanEdit } from '../../../../controllers/selectors/permissions/index.tsx';
import {
	useSwimlanesWithoutDefault,
	useIsOptimisticSwimlanes,
} from '../../../../controllers/selectors/swimlanes/index.tsx';
import { DefaultSwimlaneNameField } from './default-swimlane-name-field/index.tsx';
import { EmptyView } from './empty-view/index.tsx';
import messages from './messages.tsx';

export const QueryStrategy = ({
	handleEdit,
	handleDelete,
	handleRank,
	openCreateModal,
}: {
	handleEdit: (queryItem: QueryItem) => void;
	handleDelete: (queryItem: QueryItem) => void;
	handleRank: (queryItem: QueryItem, afterQueryItemId: QueryItem['id'] | null) => void;
	openCreateModal: () => void;
}) => {
	const { formatMessage } = useIntl();
	const [swimlanes] = useSwimlanesWithoutDefault();

	const [canEdit] = useCanEdit();

	const queryItems = useMemo(
		() =>
			swimlanes.map((swimlane) => ({
				id: String(swimlane.id),
				name: swimlane.name,
				description: swimlane.description,
				query: swimlane.query,
			})),
		[swimlanes],
	);

	const [isOptimisticList] = useIsOptimisticSwimlanes();
	const addButtonRef = useExternalRowFocusRecovery(queryItems);

	return (
		<SwimlaneQueryListContainer data-testid={dataTestIds.queryList}>
			<SwimlaneQueryListHeader>
				{formatMessage(messages.createSwimlaneDescription)}
				{canEdit && (
					<Button
						ref={addButtonRef}
						appearance="primary"
						onClick={openCreateModal}
						testId={dataTestIds.createSwimlaneQueryButton}
					>
						{formatMessage(messages.createButtonLabel)}
					</Button>
				)}
			</SwimlaneQueryListHeader>
			<QueryList
				queryItems={queryItems}
				isOptimisticList={isOptimisticList}
				isReadOnly={!canEdit}
				onDelete={handleDelete}
				onEdit={handleEdit}
				onRank={handleRank}
				emptyView={<EmptyView onAdd={openCreateModal} />}
			/>
			<DefaultSwimlaneNameField canEdit={canEdit} />
		</SwimlaneQueryListContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneQueryListHeader = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneQueryListContainer = styled.div({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRow: 'auto',
	gridTemplateColumns: '1fr',
	gap: token('space.250'),
});
