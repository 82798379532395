import type { ReactNode } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	PUBLIC_SHARE,
	GROUP_SHARE,
	PROJECT_SHARE,
	OPEN_SHARE,
	USER_SHARE,
	SharePermission,
	PROJECT_UNKNOWN_SHARE,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { User as PickerUser } from '@atlassian/jira-user-picker/src/model/index.tsx';
import type {
	ENTITY_DESCRIPTION_FIELD_NAME,
	ENTITY_NAME_FIELD_NAME,
	PRIVATE_SHARE,
	SHARE_PERMISSIONS_FIELD_NAME,
	EDIT_PERMISSIONS_FIELD_NAME,
} from './constants.tsx';

type PrivateShare = {
	readonly type: typeof PRIVATE_SHARE;
};

export type ShareType =
	| typeof GROUP_SHARE
	| typeof PRIVATE_SHARE
	| typeof PROJECT_SHARE
	| typeof PROJECT_UNKNOWN_SHARE
	| typeof PUBLIC_SHARE
	| typeof USER_SHARE
	| typeof OPEN_SHARE;

export type SharePermissionOrPrivate = SharePermission | PrivateShare; // "PrivateShare" exists / makes sense only in shareable-entity-modal, for dropdown value. Because if entity is private - it is not shared

export type SharingPermissionKey = 'globalsharing';
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilterSharingPermissionKey = 'filter';
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DashboardSharingPermissionKey = 'dashboard';
export type SharingPermissionType =
	| typeof FilterSharingPermissionKey
	| typeof DashboardSharingPermissionKey;

export type Option = {
	isDisabled: boolean;
	value: ShareType;
	messageDescriptor: MessageDescriptor;
	icon: ReactNode;
	label?: string;
};

export type Options = Option[];

export type User = Flow.Diff<
	PickerUser,
	{
		value: string;
	}
>;

export type ServerValidationErrorFieldKey =
	| typeof ENTITY_NAME_FIELD_NAME
	| typeof SHARE_PERMISSIONS_FIELD_NAME
	| typeof EDIT_PERMISSIONS_FIELD_NAME
	| typeof ENTITY_DESCRIPTION_FIELD_NAME;

export type ServerValidationError = string;
export const toServerValidationError = (value: string): ServerValidationError => value;

export type ServerValidationErrors = Record<ServerValidationErrorFieldKey, ServerValidationError>;

export type OperationData = {
	readonly name: string;
	readonly description: string;
	readonly owner?: User;
	readonly sharePermissions: SharePermission[];
	// This field should be made non optional when we allow filters to share editing rights.
	readonly editPermissions: SharePermission[];
};

export type OperationResult<OR> = OR;

type AppProps<OR> = {
	readonly title?: string;
	readonly headingTitleLevel?: 1 | 2 | 3 | 4 | 5 | 6;
	readonly actionButtonCaption: string;
	readonly initialName: string | undefined;
	readonly initialDescription: string | undefined;
	readonly initialOwner: User | undefined;
	readonly sharePermissions: SharePermission[] | undefined;
	readonly viewersCustomMessage?: string | undefined;
	readonly nameTooltip?: string | undefined;
	readonly editPermissions: SharePermission[] | undefined;
	readonly avoidInitialPublicSharePermission?: boolean;
	readonly allowSharePublicOrganizationOnly?: boolean;
	readonly isNameReadOnly?: boolean;
	readonly forceHideEditors?: boolean;
	readonly getServerSideValidationErrors: (arg1: ValidationError) => ServerValidationErrors;
	// eslint-disable-next-line jira/react/handler-naming
	readonly operation: (arg1: OperationData) => Promise<OR>;
	readonly onOperationSucceed: (
		arg1: OperationData,
		arg2: OperationResult<OR>,
		arg3: UIAnalyticsEvent,
	) => void;
	readonly onOperationFailed: (arg1: Error, arg2: UIAnalyticsEvent) => void;
	readonly onCancel: () => void;
};

export type InternalProps<OR> = AppProps<OR> & {
	readonly isShareScopeLoading: boolean;
	readonly isEditorListShown: boolean;
	readonly shareeOptions: Option[];
	readonly editOptions: Option[];
};

export type ExternalProps<OR> = AppProps<OR> & {
	readonly isShareScopeLoading?: boolean;
	readonly sharingPermissionType: 'dashboard' | 'filter';
};

export type ShareTypeProperties = {
	messageDescriptor: MessageDescriptor;
	icon: ReactNode;
};
