import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { SortableTable } from '@atlassian/jira-software-board-settings-sortable-table/src/ui/sortable-table/index.tsx';
import { dataTestIds } from '../../common/constants/data-test.tsx';
import type { QueryListProps, ColorListProps } from '../../common/types/index.tsx';
import { useInternalRowFocusRecovery } from '../../common/ui/focus-handler/index.tsx';
import { useTableHeadAndRows, useTableHeadAndRowsForColorItems } from './utils.tsx';

export const QueryList = ({
	queryItems,
	isReadOnly,
	onEdit,
	onDelete,
	onRank,
	emptyView,
}: QueryListProps) => {
	const ref = useInternalRowFocusRecovery(queryItems);
	const { head, rows } = useTableHeadAndRows({
		isReadOnly,
		items: queryItems,
		onEdit,
		onDelete,
	});

	const handleOnRank = useCallback(
		(itemIndex: number, afterItemIndex: number | null) => {
			const afterQueryItemId = afterItemIndex != null && queryItems[afterItemIndex]?.id;
			onRank(queryItems[itemIndex], afterQueryItemId || null);
		},
		[queryItems, onRank],
	);

	const empty = !queryItems || !queryItems.length;

	return (
		<Container data-testid={dataTestIds.queryList} ref={ref}>
			<SortableTable head={head} rows={rows} onRank={handleOnRank} isReadOnly={isReadOnly} />
			{empty && <EmptyViewContainer>{emptyView}</EmptyViewContainer>}
		</Container>
	);
};

export const ColorList = ({
	colorItems,
	isNonQueryColor = false,
	headerName = '',
	staleColorWarningMessage,
	isReadOnly,
	onEdit,
	onDelete,
	onRank,
	onColorChange,
	onResetToGlobal,
	emptyView,
}: ColorListProps) => {
	const ref = useInternalRowFocusRecovery(colorItems);

	const { head, rows } = useTableHeadAndRowsForColorItems({
		isReadOnly,
		isNonQueryColor,
		headerName,
		items: colorItems,
		onEdit,
		onDelete,
		onColorChange,
		onResetToGlobal,
		staleColorWarningMessage,
	});

	const handleOnRank = useCallback(
		(itemIndex: number, afterItemIndex: number | null) => {
			const afterQueryItemId = afterItemIndex != null && colorItems[afterItemIndex]?.id;
			onRank(colorItems[itemIndex], afterQueryItemId || null);
		},
		[colorItems, onRank],
	);

	const empty = !colorItems || !colorItems.length;

	return (
		<Container data-testid={dataTestIds.queryList} ref={ref}>
			<SortableTable
				head={head}
				rows={rows}
				onRank={handleOnRank}
				isReadOnly={isReadOnly || isNonQueryColor}
			/>
			{empty && <EmptyViewContainer>{emptyView}</EmptyViewContainer>}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyViewContainer = styled.div({
	borderBottom: `${token('color.border')} 1px solid`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginBottom: token('space.0'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > table': {
		borderBottom: 'none',
		width: '100%',
	},
});
