import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'software-board-settings-general-filter-settings.board-filter-query.heading',
		defaultMessage: 'Filter query',
		description: 'Header text shown for the box where we display filter query of the board',
	},
	editFilterQueryLink: {
		id: 'software-board-settings-general-filter-settings.board-filter-query.edit-filter-query-link',
		defaultMessage: 'Edit filter query',
		description: 'Link text to open edit filter query',
	},
	addAProjectToYourQueryTitle: {
		id: 'software-board-settings-general-filter-settings.board-filter-query.add-a-project-to-your-query-title',
		defaultMessage: 'Add a project to your query',
		description: 'Title for the add a project to your query warning section message',
	},
	addAProjectToYourQueryDescription: {
		id: 'software-board-settings-general-filter-settings.board-filter-query.add-a-project-to-your-query-description',
		defaultMessage:
			"The current board filter doesn't include a project, so it will search for issues across all the projects on your site. This impacts load times, and your team may struggle to access the board, issues or sprints.",
		description: 'Description for the add a project to your query warning section message',
	},
	addAProjectToYourQueryDescriptionIssueTermRefresh: {
		id: 'software-board-settings-general-filter-settings.board-filter-query.add-a-project-to-your-query-description-issue-term-refresh',
		defaultMessage:
			"The current board filter doesn't include a project, so it will search for work items across all the projects on your site. This impacts load times, and your team may struggle to access the board, work items or sprints.",
		description: 'Description for the add a project to your query warning section message',
	},
	readMoreAboutJql: {
		id: 'software-board-settings-general-filter-settings.board-filter-query.read-more-about-jql',
		defaultMessage: '<button>Read more about JQL for board filters</button>.',
		description: 'Action button for the warning section message',
	},
});
