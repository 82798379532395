import React from 'react';
import EditorPanelIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import { Box, Grid, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { dataTestIds } from '../../../../common/constants/data-test.tsx';
import messages from './messages.tsx';

export const FieldLimit = () => {
	const { formatMessage } = useIntl();

	return (
		<Grid
			xcss={containerStyles}
			testId={dataTestIds.fieldLimitWarning}
			templateColumns="auto 1fr"
			alignItems="center"
			columnGap="space.050"
		>
			<EditorPanelIcon
				// decorative icon
				label=""
				LEGACY_size="medium"
				spacing="spacious"
			/>
			<Box aria-live="polite" xcss={textStyles}>
				{formatMessage(messages.warning)}
			</Box>
		</Grid>
	);
};

const textStyles = xcss({
	color: 'color.text.subtle',
});

const containerStyles = xcss({
	alignItems: 'center',
	height: 'space.500',
	padding: 'space.100',
	marginTop: 'space.100',
});
