import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

interface CustomHeaderProps {
	headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
	title: string;
}

const ActionModalCustomHeader = ({ headingLevel = 1, title }: CustomHeaderProps) => {
	// Dynamically render the heading tag based on the level
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const Heading = `h${headingLevel}` as keyof JSX.IntrinsicElements;

	return (
		<CustomHeaderWrapper>
			<DynamicHeading as={Heading}>{title}</DynamicHeading>
		</CustomHeaderWrapper>
	);
};

export default ActionModalCustomHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DynamicHeading = styled.div({
	font: token('font.heading.medium'),
});

const headerStyles = {
	display: 'flex',
	padding: `${token('space.300')} ${token('space.300')} 22px`,
	alignItems: 'center',
	justifyContent: 'space-between',
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const CustomHeaderWrapper = styled.div(headerStyles);
