import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NameWrapperOld = styled.div({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	marginLeft: token('space.100'),
	minWidth: 0,
});

const NameWrapperNew = ({ children }: { children: ReactNode }) => (
	<Box xcss={nameWrapperStyles}>{children}</Box>
);

const nameWrapperStyles = xcss({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	minWidth: '0',
});

export const NameWrapper = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_3'),
	NameWrapperNew,
	NameWrapperOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapperOld = styled.div({
	flex: '0 0 auto',
	paddingTop: token('space.025'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.025'),
});

const IconWrapperNew = ({ children }: { children: ReactNode }) => (
	<Box xcss={iconWrapperStyles}>{children}</Box>
);

const iconWrapperStyles = xcss({
	display: 'flex',
	flex: '0 0 auto',
	// Match the padding of the Avatar component
	padding: 'space.025',
});

export const IconWrapper = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_3'),
	IconWrapperNew,
	IconWrapperOld,
);
